import "./CollectionImport.scss";
import { useState, useEffect, ReactNode } from "react";
import { useParams, useHistory } from "react-router-dom";
import * as CollectionResource from "../../resources/collections";
import { CollectionWithSuper } from "../../models";
import { addError, setCrumbs, clearCrumbs } from "../../store";
import { useAppDispatch } from "../../hooks";
import {
  Card,
  TextArea,
  ButtonGroup,
  Button,
  HTMLTable,
  HTMLSelect,
  Overlay,
  ProgressBar,
  Tag,
  FormGroup,
} from "@blueprintjs/core";
import parseCSV from "csv-parse/lib/sync";

interface ImportEntry {
  title: string;
  image?: string;
  fields: Record<string, any>;
  modifiers?: Array<string>;
}

interface ImportMessage {
  type: string;
  message: string;
}

export default function CollectionImport() {
  const dispatch = useAppDispatch();
  const params = useParams<{ collectionID: string }>();
  const history = useHistory();
  const [collection, setCollection] = useState({} as CollectionWithSuper);
  const [inputJSON, setInputJSON] = useState("[]");
  const [previewMode, setPreviewMode] = useState(false);
  const [previewState, setPreviewState] = useState({
    error: null as string | null,
    items: [] as Array<ImportEntry>,
  });
  const [importState, setImportState] = useState({
    started: false,
    failed: false,
    completed: 0,
    total: 0,
    messages: [] as Array<ImportMessage>,
  });
  const [chunkSize, setChunkSize] = useState(5);

  useEffect(() => {
    CollectionResource.getCollectionDetail(params.collectionID).then(
      (resp) => {
        setCollection(resp.collection);
        dispatch(
          setCrumbs({
            title: resp.collection.name,
            items: [
              { to: `/users/${resp.owner.id}`, text: resp.owner.username },
              {
                to: `/collections/${resp.collection.id}`,
                text: resp.collection.name,
              },
              "Bulk Import",
            ],
          })
        );
      },
      (err) => dispatch(addError(err))
    );
    return () => {
      dispatch(clearCrumbs());
    };
  }, [dispatch, params.collectionID]);

  useEffect(() => {
    try {
      const items = JSON.parse(inputJSON);
      if (!Array.isArray(items)) {
        setPreviewState({
          error: "Import list must be a JSON array.",
          items: [],
        });
      } else {
        setPreviewState({
          error: null,
          items: items as Array<ImportEntry>,
        });
      }
    } catch {
      try {
        const results = parseCSV(inputJSON, { columns: true });
        console.log(results);
        const converted: Array<ImportEntry> = results.map((r: any) => {
          let i = {
            title: r.title,
            image: r.image,
            fields: r,
            modifiers: r.modifiers
              ?.split(",")
              .map((s: string) => s.trim())
              .filter((s: string) => s !== ""),
          };
          delete i.fields.title;
          delete i.fields.image;
          delete i.fields.modifiers;
          return i;
        });
        console.log(converted);
        setPreviewState({
          error: null,
          items: converted,
        });
      } catch {
        setPreviewState({
          error: "Failed to parse input as JSON or CSV",
          items: [],
        });
      }
    }
  }, [inputJSON]);

  function startImport(): void {
    setImportState({
      ...importState,
      started: true,
      total: previewState.items.length,
      messages: [{ type: "normal", message: "Import started..." }],
    });
  }

  function importChunk(): void {
    CollectionResource.importItems(
      collection.id,
      previewState.items.slice(
        importState.completed,
        importState.completed + chunkSize
      )
    ).then(
      ({ count, messages }) => {
        setImportState({
          ...importState,
          completed: importState.completed + count,
          messages: [
            ...importState.messages,
            ...messages.map((m) => ({ type: "error", message: m })),
          ],
        });
      },
      (err) => {
        setImportState({
          ...importState,
          failed: true,
          messages: [
            ...importState.messages,
            {
              type: "error",
              message: `Hit unhandled error. Stopping import. Response = ${JSON.stringify(
                err
              )}`,
            },
          ],
        });
      }
    );
  }

  function previewTable(): ReactNode {
    const fieldNames: Array<string> = [];
    previewState.items.forEach(i => {
      Object.keys(i.fields).forEach(k => {
        if (!fieldNames.includes(k)) {
          fieldNames.push(k);
        }
      });
    });

    function stringify(value: any): string {
      if (value == null || typeof value === "undefined") {
        return "";
      }
      if (typeof value === "string") {
        return value;
      }
      return JSON.stringify(value);
    }

    return <HTMLTable bordered={true} striped={true} condensed={true}>
    <tbody>
      <tr>
        <th>Index</th>
        <th>Title</th>
        {fieldNames.map(f => <th key={f}>{f}</th>)}
        <th>Modifiers</th>
        <th>Image URL</th>
      </tr>
      {previewState.items.map((item, index) => (
        <tr key={index}>
          <td>{index}</td>
          <td>{item.title}</td>
          {fieldNames.map(f => <td key={f}>{stringify(item.fields[f])}</td>)}
          <td>
            {item.modifiers?.map(
              (m) =>
                m && (
                  <Tag className="modifier" key={m}>
                    {m}
                  </Tag>
                )
            )}
          </td>
          <td>{item.image}</td>
        </tr>
      ))}
    </tbody>
  </HTMLTable>;
  }

  useEffect(() => {
    if (importState.started && !importState.failed) {
      if (importState.completed < importState.total) {
        importChunk();
      } else {
        setImportState((i) => ({
          ...i,
          messages: [...i.messages, { type: "success", message: "Done!" }],
        }));
      }
    } // eslint-disable-next-line
  }, [
    importState.started,
    importState.failed,
    importState.completed,
    importState.total,
  ]);

  function goToCollection(): void {
    history.push(`/collections/${collection.id}`);
  }

  return (
    <Card className="Page CollectionImport">
      <ButtonGroup fill={true}>
        <Button icon={previewMode ? "edit" : "zoom-in"} onClick={() => setPreviewMode(!previewMode)}>
          {previewMode ? "Raw" : "Preview"}
        </Button>
        <Button
          icon="import"
          onClick={() => startImport()}
          disabled={!previewMode || !!previewState.error || previewState.items.length === 0}
          intent="success"
        >
          Start Import ({previewState.items.length} items)
        </Button>
      </ButtonGroup>
      {previewMode ? (
        previewState.error ? (
          <Card>Error: {previewState.error}</Card>
        ) : (
          previewTable()
        )
      ) : (
        <>
          <TextArea
            fill={true}
            value={inputJSON}
            onChange={(e) => setInputJSON(e.target.value)}
          />
          <FormGroup label="Chunk size:" inline={true}>
            <HTMLSelect
              value={chunkSize}
              options={[1, 5, 10]}
              onChange={(e) => setChunkSize(parseInt(e.currentTarget.value))}
            />
          </FormGroup>
        </>
      )}
      <Overlay isOpen={importState.started} className="import-modal">
        <Card elevation={3} className="overlay-card">
          <div className="progress-element">
            Progress: {importState.completed} of {importState.total}
          </div>
          <ProgressBar
            intent={importState.failed ? "danger" : "primary"}
            value={importState.completed / importState.total}
          />
          <div className="progress-element import-messages">
            {importState.messages.map((m, i) => (
              <div key={i} className={`message ${m.type}`}>
                {m.message}
              </div>
            ))}
          </div>
          {importState.completed >= importState.total && (
            <Button
              fill={true}
              intent="success"
              onClick={() => goToCollection()}
              icon="arrow-right"
            >
              To {collection.name}
            </Button>
          )}
        </Card>
      </Overlay>
    </Card>
  );
}
