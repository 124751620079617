import "./igdb.scss";
import {
  Alignment,
  Button,
  ControlGroup,
  InputGroup,
  MenuItem,
} from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import { ReactNode, useEffect, useState } from "react";
import { useAppDispatch } from "../../../hooks";
import { CollectionSchemaField } from "../../../models";
import { IntegrationResource } from "../../../resources";
import { addError } from "../../../store";
import { Integration, MutableItem } from "./base";

interface Game {
  id: string;
  name: string;
  url: string;
  platforms: Array<{
    name: string;
    abbreviation: string;
  }>;
  cover_url?: string;
  cover_thumb?: string;
}

function IGDBEdit(props: {
  title: string | undefined;
  game: Game | undefined;
  updateValue: (value: Game) => void;
  children?: ReactNode;
}) {
  const dispatch = useAppDispatch();
  const [state, setState] = useState({
    query: props.game ? props.game.name : (props.title || ""),
    gameOptions: (props.game ? [props.game] : []) as Array<Game>,
    loading: false,
  });

  useEffect(() => {
    if (state.query === "" && props.title) {
      doSearch(props.title)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.title]);

  function doSearch(setQuery?: string): void {
    const u = { ...state, loading: true };
    if (setQuery) {
      u.query = setQuery;
    }
    setState(u);
    IntegrationResource.queryIntegration("igdb", u.query).then(
      (resp) => {
        if (resp.error) {
          dispatch(addError(resp.error));
          setState({ ...state, loading: false });
        } else {
          const r = resp.result as Record<string, any>;
          setState(s => ({
            ...s,
            loading: false,
            gameOptions: r.games,
          }));
        }
      },
      (err) => {
        setState({ ...state, loading: false });
        dispatch(addError(err));
      }
    );
  }

  const GameSelect = Select.ofType<Game>();

  function gameName(game: Game): string {
    if (game.platforms) {
      return `${game.name} (${game.platforms
        .map((p) => p.abbreviation)
        .join(", ")})`;
    }
    return game.name;
  }

  return (
    <>
      <ControlGroup fill={true}>
        <InputGroup
          fill={true}
          value={state.query}
          onChange={(e) => setState({ ...state, query: e.target.value })}
          placeholder="Game name"
          disabled={state.loading}
        />
        <Button
          icon="search"
          onClick={() => doSearch()}
          disabled={state.loading || state.query === ""}
        />
      </ControlGroup>
      <ControlGroup fill={true}>
        <GameSelect
          filterable={false}
          fill={true}
          disabled={state.loading}
          popoverProps={{ minimal: true }}
          items={state.gameOptions}
          itemRenderer={(game, { handleClick, modifiers, query }) => {
            const text = gameName(game);
            return (
              <MenuItem
                active={modifiers.active}
                // label={text}
                key={game.id}
                text={text}
                onClick={() => props.updateValue(game)}
              />
            );
          }}
          onItemSelect={(i) => props.updateValue(i)}
          noResults={<i>No results</i>}
        >
          <Button
            fill={true}
            alignText={Alignment.LEFT}
            text={
              props.game ? (
                gameName(props.game)
              ) : (
                <i>Use the search above</i>
              )
            }
            rightIcon="double-caret-vertical"
            disabled={state.loading}
          />
        </GameSelect>
      </ControlGroup>
      {props.children}
    </>
  );
}

export class IGDBIntegration extends Integration<Game> {
  renderValue(
    field: CollectionSchemaField,
    g?: Game
  ): ReactNode {
    return (
      <div className="igdb-box-art">
        {g && (
          <a href={g.url} target="_blank" rel="noreferrer">
            {g.cover_thumb ? (
              <img src={g.cover_thumb} alt={`Box Art - ${g.name}`} />
            ) : (
              <>(no image)</>
            )}
          </a>
        )}
      </div>
    );
  }

  renderFormField(
    item: MutableItem,
    field: CollectionSchemaField,
    game: Game | undefined,
    updateValue: (game: Game | undefined) => void
  ): ReactNode {
    return (
      <IGDBEdit title={item.title} game={game} updateValue={updateValue}>
        {this.renderValue(field, game)}
      </IGDBEdit>
    );
  }
}
