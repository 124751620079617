import "./CollectionCreate.scss";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { setCrumbs, clearCrumbs } from "../../store";
import { useAppDispatch } from "../../hooks";
import {
  Card,
} from "@blueprintjs/core";
import CollectionForm from "../collections/CollectionForm";

export default function CollectionCreate() {
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      setCrumbs({
        title: "New Collection",
        items: ["New Collection"],
      })
    );
    return () => {
      dispatch(clearCrumbs());
    };
  }, [dispatch]);

  function openCollection(collectionID: string): void {
    history.push(`/collections/${collectionID}`);
  }

  return <Card className="CollectionCreate">
    <CollectionForm onSave={(c) => openCollection(c.id)} />
  </Card>;
}
