import "./googlebooks.scss";
import { Alignment, Button, ControlGroup, MenuItem } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import { ReactNode, useState } from "react";
import { useAppDispatch } from "../../../hooks";
import { CollectionSchemaField } from "../../../models";
import { IntegrationResource } from "../../../resources";
import { addError } from "../../../store";
import { Integration, MutableItem } from "./base";

interface Book {
  id: string;
  title: string;
  subtitle: string | null;
  authors: Array<string>;
  isbn10: string | null;
  isbn13: string | null;
  thumbnail: string | null;
  openlibrary_url: string | null;
}

function libraryThingURL(b: Book): string {
  return `https://www.librarything.com/search.php?search=${
    b.isbn13 || b.title
  }`;
}

function coalesceImage(book: Book): ReactNode {
  const nonNull = [book.openlibrary_url, book.thumbnail].filter(u => !!u) as Array<string>;
  if (nonNull.length === 0) {
    return <>(no cover available)</>;
  }
  let src=nonNull[0];
  return <img src={src} alt="Cover" onError={() => {
    if (nonNull.length > 1 && src !== nonNull[1]) {
      src = nonNull[1];
    }
  }}/>;
}

export class GoogleBooksIntegration extends Integration<Book> {
  renderValue(field: CollectionSchemaField, book?: Book): ReactNode {
    return (
      <div className="book-cover">
        {book && (
          <a
            href={libraryThingURL(book)}
            target="_blank"
            rel="noreferrer"
            title="View on LibraryThing"
          >
            {coalesceImage(book)}
          </a>
        )}
      </div>
    );
  }

  renderFormField(
    item: MutableItem,
    field: CollectionSchemaField,
    book: Book | undefined,
    updateValue: (book: Book | undefined) => void
  ): ReactNode {
    return <BookLookup item={item} book={book} updateValue={updateValue}>
      {this.renderValue(field, book)}
      </BookLookup>;
  }
}

function BookLookup(props: {
  item: MutableItem;
  book: Book | undefined;
  updateValue: (value: Book) => void;
  children?: ReactNode;
}) {
  const dispatch = useAppDispatch();
  const [state, setState] = useState({
    options: props.book ? [props.book] : ([] as Array<Book>),
    loading: false,
  });

  function doSearch(): void {
    setState({ ...state, loading: true });
    IntegrationResource.queryIntegration("googlebooks", {
      title: props.item.title.split(":")[0],
      author: (props.item.fields["author"] as string || "").split(",")[0],
    }).then(
      (resp) => {
        if (resp.error) {
          dispatch(addError(resp.error));
          setState({ ...state, loading: false });
        } else {
          const books = (resp.result as { books: Array<Book> }).books;
          setState({
            options: books,
            loading: false,
          });
        }
      },
      (err) => {
        setState({ ...state, loading: false });
        dispatch(addError(err));
      }
    );
  }

  const BookSelect = Select.ofType<Book>();
  function bookName(book: Book): string {
    const title = book.subtitle ? `${book.title}: ${book.subtitle}` : book.title;
    const authors = book.authors.join(", ");
    return `${title} (${authors})`;
  }

  return (
    <>
    <ControlGroup fill={true}>
      <BookSelect
        fill={true}
        filterable={false}
        disabled={state.loading || state.options.length === 0}
        popoverProps={{ minimal: true }}
        items={state.options}
        itemRenderer={(book, { handleClick, modifiers, query }) => {
          return (
            <MenuItem
              active={modifiers.active}
              key={book.id}
              text={bookName(book)}
              onClick={() => props.updateValue(book)}
            />
          );
        }}
        onItemSelect={(i) => props.updateValue(i)}
        noResults={<i>No results</i>}
      >
        <Button
          fill={true}
          alignText={Alignment.LEFT}
          text={props.book ? bookName(props.book) : (state.options.length > 0 ? "Select from dropdown" : "Click to Search")}
          rightIcon={state.options.length > 0 ? "double-caret-vertical" : undefined}
          disabled={state.loading || state.options.length === 0}
        />
      </BookSelect>
      <Button icon="search" disabled={state.loading || !props.item.title} onClick={() => doSearch()} />
    </ControlGroup>
    {props.children}
    </>
  );
}
