import { ImageEntry } from "../models";

export function imageURL(image: ImageEntry, size: "small" | "full"): string {
  // TODO better than this
  return `https://images.stuff.alexforan.com/${size}/${image.token}.jpg`;
}

type SuccessCallback = (base64Data: string) => void;

function bufferToString(buffer: ArrayBuffer): string {
  return new TextDecoder().decode(buffer);
}

export function resizeImageFile(file: File | string, maxSize: number, successCallback: SuccessCallback, errorCallback: (e: any) => void): void {
  if (typeof file === "string") {
    loadAndResizeImage(file, maxSize, successCallback, errorCallback);
  } else {
    const reader = new FileReader();
    reader.onerror = errorCallback;
    reader.onload = () => {
      if (reader.result) {
        const url = typeof reader.result === "string" ? reader.result : bufferToString(reader.result as ArrayBuffer);
        loadAndResizeImage(url, maxSize, successCallback, errorCallback);
      }
    };
    reader.readAsDataURL(file);
  }
};

function loadAndResizeImage(url: string, maxSize: number, successCallback: SuccessCallback, errorCallback: (e: any) => void): void {
  const image = new Image();
  image.setAttribute("crossOrigin", "anonymous");
  image.onload = () => {
    let cw = image.width;
    let ch = image.height;
    if (cw > maxSize) {
      ch *= maxSize / cw;
      cw = maxSize;
    }
    if (ch > maxSize) {
      cw *= maxSize / ch;
      ch = maxSize;
    }
    const canvas = document.createElement("canvas");
    canvas.width = cw;
    canvas.height = ch;
    canvas.style.visibility = "none";
    document.body.appendChild(canvas);
    const context = canvas.getContext("2d");
    if (context == null) {
      errorCallback("Cannot acquire 2D context.");
    } else {
      context.drawImage(image, 0, 0, cw, ch);
      let data = canvas.toDataURL("image/jpeg", 0.9);
      successCallback(extractDataFromURL(data));
      document.body.removeChild(canvas);
    }
  };
  image.src = url;
};

function extractDataFromURL(data: string): string {
  return data.split(";base64,", 2)[1];
}
