import "./CompletionBar.scss";
import { ProgressBar } from "@blueprintjs/core";

export default function CompletionBar(props: {
  count: number,
  total: number,
  className?: string,
}) {
  const percent = props.total === 0 ? 0 : (props.count >= props.total ? 1 : props.count/props.total);
  const intent = percent === 1 ? "success" : "primary";

  return <div className="CompletionBar">
    <div className="bar"><ProgressBar className={props.className} animate={false} stripes={false} value={percent} intent={intent}/></div>
    <div className="ratio">{props.count} / {props.total}</div>
    <div className="percent">
      ({Math.round(percent * 1000)/10}%)
    </div>
  </div>;
}
