import "./Header.scss";
import { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import { Card, Icon } from "@blueprintjs/core";
import * as AuthHelper from "../../helpers/auth";
import { getCurrentUser, setCurrentUser, addError } from "../../store";
import { useAppSelector, useAppDispatch } from "../../hooks";
import * as AuthResource from "../../resources/auth";
import ButtonLink from "./ButtonLink";

export default function Header() {
  const currentUser = useAppSelector(getCurrentUser);
  const dispatch = useAppDispatch();
  const location = useLocation();

  function login() {
    window.localStorage.setItem("return-to", location.pathname+location.search);
    AuthResource.getOAuthURL().then(
      (resp) => window.location.assign(resp.authURL),
      (err) => dispatch(addError(err))
    );
  }

  function logout() {
    AuthHelper.clearToken();
    dispatch(setCurrentUser(null));
  }

  return (
    <Card id="Header" elevation={2}>
      <Link className="home-link" to="/">
        Stuff Collector
      </Link>
      <span className="spacer" />
      {currentUser ? (
        <Fragment>
          <Link className="header-link" to={`/users/${currentUser.id}`} title="Stuff Collector - Home">
            <span className="username">{currentUser.username}</span>
            <Icon icon="user" size={20} />
          </Link>
          <Link className="header-link" to="/settings">
            <Icon icon="cog" size={20} />
          </Link>
          <ButtonLink onClick={logout} title="Log out" className="header-link">
            <Icon icon="log-out" size={20} />
          </ButtonLink>
        </Fragment>
      ) : (
        <ButtonLink onClick={login} title="Log in" className="header-link">
          <Icon icon="log-in" size={20} />
        </ButtonLink>
      )}
    </Card>
  );
}
