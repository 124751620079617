import "./ItemDetail.scss";
import { ReactNode, useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import {
  ItemWithSuper,
  CollectionWithSuper,
  PublicUser,
  CollectionSchemaField,
  ImageEntry,
  Item,
  ItemWithChildren,
  CollectionDetail,
} from "../../models";
import * as CollectionResource from "../../resources/collections";
import Loader from "../shared/Loader";
import { addError, getCurrentUser, setCrumbs, clearCrumbs } from "../../store";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { QueryParams } from "../../helpers/url";
import * as ImageHelper from "../../helpers/images";
import {
  Card,
  HTMLTable,
  Button,
  InputGroup,
  NumericInput,
  Checkbox,
  HTMLSelect,
  Switch,
  Icon,
} from "@blueprintjs/core";
import ItemThumbnail from "../collections/ItemThumbnail";
import SuperItemSuggest from "../collections/SuperItemSuggest";
import ItemImageField from "../collections/ItemImageField";
import { IntegrationFormField } from "../collections/IntegrationFormField";
import { withoutAny } from "../../helpers/util";
import { FieldValue } from "../../models/item";

export default function ItemDetail() {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(getCurrentUser);
  const params = useParams<{ collectionID: string; itemID: string }>();
  const history = useHistory();
  const queryParams = new QueryParams(history.location.search);
  const childID = queryParams.get("child");
  const [editMode, setEditMode] = useState(queryParams.get("mode") === "edit");

  const [state, setState] = useState({
    loading: true,
    collection: {} as CollectionWithSuper,
    childCollection: undefined as CollectionDetail | undefined,
    item: {} as ItemWithSuper | ItemWithChildren,
    owner: {} as PublicUser,
  });
  const [formState, setFormState] = useState({
    loading: false,
    superItem: {} as Item | undefined,
    title: "",
    quantity: 1,
    fields: {} as Record<string, FieldValue>,
    modifiers: {} as Record<string, boolean>,
    images: [] as Array<ImageEntry>,
  });

  const itemSuper = state.item as ItemWithSuper;
  const itemChild = state.item as ItemWithChildren;
  const showControls =
    currentUser?.id === state.owner.id ||
    (currentUser && state.collection.curators?.includes(currentUser.id)) ||
    false;

  useEffect(() => {
    CollectionResource.getItemDetail(
      params.collectionID,
      params.itemID,
      childID
    ).then(
      (resp) => {
        dispatch(
          setCrumbs({
            title: `${resp.collection.name} - ${resp.item.title}`,
            items: [
              { to: `/users/${resp.owner.id}`, text: resp.owner.username },
              {
                to: `/collections/${resp.collection.id}`,
                text: resp.collection.name,
              },
              {
                to: `/collections/${resp.collection.id}/items/${resp.item.id}`,
                text: resp.item.title,
              },
            ],
          })
        );
        setState({
          loading: false,
          collection: resp.collection,
          childCollection: resp.childCollection,
          item: resp.item,
          owner: resp.owner,
        });
        resetForm(resp.item, resp.collection);
      },
      (err) => dispatch(addError(err))
    );
    return () => {
      dispatch(clearCrumbs());
    };
  }, [dispatch, params.collectionID, params.itemID, childID]);

  function resetForm(
    item: ItemWithSuper,
    collection: CollectionWithSuper
  ): void {
    setFormState({
      loading: false,
      superItem: item.super_item_detail,
      title: item.title,
      quantity: item.quantity,
      fields: { ...item.fields },
      modifiers: Object.fromEntries(
        (collection.super_collection
          ? [
              ...collection.schema.modifiers,
              ...collection.super_collection.schema.modifiers,
            ]
          : collection.schema.modifiers
        ).map((m: string) => [m, item.modifiers.includes(m)])
      ) as Record<string, boolean>,
      images: [...item.images],
    });
  }

  function enterEditMode(): void {
    history.push("?" + queryParams.encode({ mode: "edit" }));
    setEditMode(true);
  }

  function cancelEditMode(item?: ItemWithSuper): void {
    history.push("?" + queryParams.encode({ mode: "view" }));
    setEditMode(false);
    resetForm(item || state.item, state.collection);
  }

  function boolify(f: CollectionSchemaField, value: any): any {
    if (f.type === "boolean") {
      if (value === true) {
        return "true";
      }
      if (value === false) {
        return "false";
      }
    }
    return value;
  }

  function fieldRow(
    f: CollectionSchemaField,
    includeParent: boolean = true
  ): ReactNode {
    return (
      <tr key={f.name}>
        <td className="field-title">{f.display}</td>
        <td>
          {f.type === "integration" ? (
            <IntegrationFormField
              item={formState}
              field={f}
              editing={editMode}
              value={
                editMode ? formState.fields[f.name] : (state.item.fields[f.name] ||
                  itemSuper.super_item_detail?.fields[f.name]) as any
              }
              updateValue={(value) => setFieldValue(f.name, value)}
            />
          ) : editMode ? (
            editField(f, includeParent)
          ) : (
            boolify(
              f,
              state.item.fields[f.name] ||
                itemSuper.super_item_detail?.fields[f.name] ||
                ""
            )
          )}
        </td>
      </tr>
    );
  }

  function setFieldValue(key: string, value: any): void {
    if (value === "" || value === undefined) {
      setFormState({
        ...formState,
        fields: Object.fromEntries(
          Object.entries(formState.fields).filter(([k, v]) => k !== key)
        ),
      });
    } else {
      setFormState({
        ...formState,
        fields: {
          ...formState.fields,
          ...Object.fromEntries([[key, value === "" ? undefined : value]]),
        },
      });
    }
  }

  function editField(
    f: CollectionSchemaField,
    includeParent: boolean
  ): ReactNode {
    let el = "(I don't know how to edit this???)" as ReactNode;
    if (f.type === "number") {
      el = (
        <NumericInput
          disabled={formState.loading}
          fill={true}
          value={formState.fields[f.name] as number | undefined}
          onValueChange={(v) => setFieldValue(f.name, v)}
        />
      );
    }
    if (f.type === "string") {
      el = (
        <InputGroup
          disabled={formState.loading}
          fill={true}
          value={formState.fields[f.name] as string | undefined}
          onChange={(e) => setFieldValue(f.name, e.target.value)}
        />
      );
    }
    if (f.type === "boolean") {
      el = (
        <Checkbox
          indeterminate={formState.fields[f.name] as boolean | undefined}
          disabled={formState.loading}
          onChange={(e) => setFieldValue(f.name, !formState.fields[f.name])}
        />
      );
    }
    if (f.type === "choice") {
      el = (
        <HTMLSelect
          disabled={formState.loading}
          fill={true}
          value={formState.fields[f.name] as string | number | undefined}
          onChange={(e) => setFieldValue(f.name, e.target.value)}
        >
          <option value={undefined}></option>
          {f.values && f.values.map((v) => <option key={v}>{v}</option>)}
        </HTMLSelect>
      );
    }

    return editMode &&
      includeParent &&
      formState.superItem &&
      formState.superItem.fields &&
      formState.superItem.fields[f.name] ? (
      <>
        {el}
        <div className="field-sub">
          Inherited: {formState.superItem.fields[f.name]}
        </div>
      </>
    ) : (
      el
    );
  }

  function modifierControls(): ReactNode {
    return Object.entries(formState.modifiers).map(([m, v]) => (
      <Switch
        key={m}
        label={m}
        checked={v}
        disabled={formState.loading}
        onChange={(e) => {
          const newValue = {} as Record<string, boolean>;
          newValue[m] = !v;
          setFormState({
            ...formState,
            modifiers: {
              ...formState.modifiers,
              ...newValue,
            },
          });
        }}
      />
    ));
  }

  function addImage(token: string): void {
    setFormState({
      ...formState,
      images: [...formState.images, { token: token, added: "(new)" }],
    });
  }

  function removeImage(token: string): void {
    setFormState({
      ...formState,
      images: formState.images.filter((i) => i.token !== token),
    });
  }

  function imageEditRow(image: ImageEntry): ReactNode {
    return (
      <div key={image.token} className="image-edit-row">
        <div className="image-box">
          <img src={ImageHelper.imageURL(image, "small")} alt="" />
        </div>
        <div className="image-added">{image.added}</div>
        <Button
          icon="cross"
          intent="danger"
          minimal={true}
          onClick={() => removeImage(image.token)}
        />
      </div>
    );
  }

  const formValid = !!formState.title;

  function controls(): ReactNode {
    return (
      <tr className="controls">
        <td colSpan={2}>
          {editMode ? (
            <>
              <Button
                intent="success"
                disabled={formState.loading || !formValid}
                onClick={() => saveItem()}
              >
                Save Changes
              </Button>
              <Button
                onClick={() => cancelEditMode()}
                disabled={formState.loading}
              >
                Cancel
              </Button>
            </>
          ) : (
            <Button onClick={() => enterEditMode()}>Update</Button>
          )}
        </td>
      </tr>
    );
  }

  function childTable(header?: ReactNode): ReactNode {
    return (
      <HTMLTable
        className="child-table"
        striped={true}
        bordered={true}
        condensed={true}
        interactive={true}
      >
        {header && (
          <thead>
            <tr>
              <th
                colSpan={
                  2 +
                  (state.childCollection
                    ? state.childCollection.schema.fields.length
                    : 0)
                }
              >
                {header}
              </th>
            </tr>
          </thead>
        )}
        <tbody>
          <tr>
            <th>
              <Icon icon="camera" />
            </th>
            <th>Qty</th>
            {state.childCollection?.schema.fields.map((f) => (
              <th key={f.name}>{f.display}</th>
            ))}
          </tr>
          {itemChild.children?.map((child) => (
            <tr
              key={child.id}
              onClick={() =>
                history.push(
                  `/collections/${state.childCollection?.id}/items/${child.id}`
                )
              }
            >
              <td>
                {child.images.length > 0 && (
                  <ItemThumbnail item={child} index={0} minimal={true} />
                )}
              </td>
              <td>{child.quantity}</td>
              {state.childCollection?.schema.fields.map((f) => (
                <td key={f.name}>{boolify(f, child.fields[f.name] || "")}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </HTMLTable>
    );
  }

  function saveItem(): void {
    if (formValid) {
      setFormState({
        ...formState,
        loading: true,
      });
      const updatedItem = withoutAny(state.item, ["super_item_detail", "children"]) as Item;
      updatedItem.super_item = formState.superItem?.id;
      updatedItem.title = formState.title;
      updatedItem.quantity = formState.quantity;
      updatedItem.fields = formState.fields;
      updatedItem.modifiers = Object.entries(formState.modifiers)
        .filter(([k, v]) => v)
        .map(([k, v]) => k);
      updatedItem.images = formState.images;
      CollectionResource.updateItem(state.collection.id, updatedItem).then(
        (item) => {
          const superItem = {
            ...item,
            super_item_detail: formState.superItem,
          } as ItemWithSuper;
          setState({
            ...state,
            item: superItem,
          });
          cancelEditMode(superItem);
        },
        (err) => {
          dispatch(addError(err));
          setFormState({
            ...formState,
            loading: false,
          });
        }
      );
    }
  }

  return state.loading ? (
    <Loader />
  ) : (
    <Card className="ItemDetail">
      <HTMLTable bordered={true} striped={true} condensed={true}>
        <tbody>
          {showControls && controls()}
          {state.collection.super_collection && (
            <tr>
              <td className="field-title">Parent Item</td>
              <td>
                {editMode ? (
                  <SuperItemSuggest
                    suggestFrom={state.collection.super_collection}
                    disabled={formState.loading}
                    selectedItem={itemSuper.super_item_detail}
                    onSelect={(item) =>
                      setFormState({
                        ...formState,
                        superItem: item,
                        title: item?.title || formState.title,
                      })
                    }
                  />
                ) : state.item.super_item ? (
                  <Link
                    to={`/collections/${state.collection.template}/items/${state.item.super_item}?child=${state.collection.id}`}
                  >
                    {itemSuper.super_item_detail?.title}
                  </Link>
                ) : (
                  "(none)"
                )}
              </td>
            </tr>
          )}
          <tr>
            <td className="field-title">Title</td>
            <td>
              {editMode ? (
                <InputGroup
                  disabled={formState.loading}
                  fill={true}
                  value={formState.title}
                  onChange={(e) =>
                    setFormState({ ...formState, title: e.target.value })
                  }
                />
              ) : (
                state.item.title
              )}
            </td>
          </tr>
          <tr>
            <td className="field-title">Quantity</td>
            <td>
              {editMode ? (
                <NumericInput
                  disabled={formState.loading}
                  fill={true}
                  value={formState.quantity}
                  min={0}
                  onValueChange={(v) =>
                    setFormState({ ...formState, quantity: v })
                  }
                />
              ) : (
                state.item.quantity
              )}
            </td>
          </tr>
          {state.collection.schema.fields.map((f: CollectionSchemaField) =>
            fieldRow(f, true)
          )}
          {state.collection.super_collection &&
            state.collection.super_collection.schema.fields.map(
              (f: CollectionSchemaField) => fieldRow(f, true)
            )}
          <tr>
            <td className="field-title">Modifiers</td>
            <td>
              {editMode
                ? modifierControls()
                : state.item.modifiers.length
                ? state.item.modifiers.map((m) => (
                    <span key={m} className="modifier">
                      {m}
                    </span>
                  ))
                : "(none)"}
            </td>
          </tr>
          <tr>
            <td className="field-title">Images</td>
            <td className="thumbnail-box">
              {editMode ? (
                <>
                  {formState.images.map((im) => imageEditRow(im))}
                  <ItemImageField
                    fill={true}
                    disabled={formState.loading}
                    onImageAdd={(token) => addImage(token)}
                  />
                </>
              ) : (
                <>
                  {state.item.images.map((item: ImageEntry, index: number) => (
                    <ItemThumbnail
                      key={item.token}
                      item={state.item}
                      secondary={itemSuper.super_item_detail}
                      index={index}
                      size={100}
                    />
                  ))}
                  {itemSuper.super_item_detail &&
                    itemSuper.super_item_detail.images.map(
                      (item: ImageEntry, index: number) => (
                        <ItemThumbnail
                          key={item.token}
                          item={state.item}
                          secondary={itemSuper.super_item_detail}
                          index={state.item.images.length + index}
                          size={100}
                        />
                      )
                    )}
                </>
              )}
            </td>
          </tr>
          {childID && (
            <tr className="mobile-only">
              <td className="field-title">
                Entries in{" "}
                <Link to={`/collections/${state.childCollection?.id}`}>
                  {state.childCollection?.name}
                </Link>
              </td>
              <td>{childTable()}</td>
            </tr>
          )}
          {showControls && controls()}
        </tbody>
      </HTMLTable>
      {childID && (
        <div className="mobile-hidden">
          {childTable(
            <>
              Entries in{" "}
              <Link to={`/collections/${state.childCollection?.id}`}>
                {state.childCollection?.name}
              </Link>
            </>
          )}
        </div>
      )}
    </Card>
  );
}
