import { authFetch } from "./util";

interface OAuthURL {
  authURL: string
};

export async function getOAuthURL(): Promise<OAuthURL> {
  return authFetch("GET", "/api/oauth/login");
}

interface OAuthToken {
  token: string
  newUser?: boolean
};

export async function getOAuthToken(code: string): Promise<OAuthToken> {
  return authFetch("GET", "/api/oauth/callback", null, {params: {code: code}});
}

export async function refreshToken(): Promise<OAuthToken> {
  return authFetch("POST", "/api/oauth/refresh", null);
}

export async function assumeUserSession(userID: string): Promise<OAuthToken> {
  return authFetch("GET", `/api/auth/assume/${userID}`);
}

// export async function recentCollections(): Promise<Array<CollectionSummary>> {
//   return authFetch("GET", "/api/collections/recent");
// } 

// getAuthURL: () => fetch(`${HOSTNAME}/api/oauth/login`).then(coerceResp),
// getAuthToken: (code) => fetch(`${HOSTNAME}/api/oauth/callback?code=${code}`).then(coerceResp),
