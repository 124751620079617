import * as UserResource from "../../resources/users";
import * as CollectionResource from "../../resources/collections";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card } from "@blueprintjs/core";
import { PublicUser, User, Collection } from "../../models";
import { addError, getCurrentUser, setCrumbs, clearCrumbs } from "../../store";
import { useAppDispatch, useAppSelector } from "../../hooks";
import UserCollectionList from "../collections/UserCollectionList";

export default function UserProfile() {
  const currentUser = useAppSelector(getCurrentUser);
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();
  const isSelf = params.id === currentUser?.id;

  const [state, setState] = useState({
    loading: true,
    user: {} as PublicUser | User,
    collections: [] as Array<Collection>,
  });

  useEffect(() => {
    UserResource.getUser(params.id).then(
      (user) => {
        dispatch(
          setCrumbs({
            title: `Profile - ${user.username}`,
            items: [
            {to: `/users/${user.id}`, text: user.username},
            "Profile",
          ]})
        );
        CollectionResource.listCollectionsForUser(user.id).then(
          (colls) =>
            setState({
              loading: false,
              user: user,
              collections: colls,
            }),
          (err) => dispatch(addError(err))
        );
      },
      (err) => dispatch(addError(err))
    );
    return () => {
      dispatch(clearCrumbs());
    };
  }, [dispatch, params.id, isSelf]);

  return (
    <Card className="Page UserProfile">
      {state.loading ? (
        "Loading..."
      ) : (
        <>
          <header className="page-header">Collections</header>
          <UserCollectionList collections={state.collections} loading={false} self={isSelf} />
        </>
      )}
    </Card>
  );
}
