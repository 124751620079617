import "./CollectionListItemEditRow.scss";
import { Button, Card, FormGroup, InputGroup } from "@blueprintjs/core";
import {
  CollectionListItemWithMatches,
  Collection,
  Item,
} from "../../models";
import ItemThumbnail from "../collections/ItemThumbnail";
import { Link } from "react-router-dom";

export interface EditableCollectionListItem {
  id: string;
  name: string;
  original: CollectionListItemWithMatches | null;
  flaggedForDelete: boolean;
  superItems: Array<Item>;
  requiredModifiers: Array<string>;
  ordering: number;
}

interface CollectionListItemEditProps {
  collection: Collection;
  item: EditableCollectionListItem;
  index: number;
  updateItem: (id: string, update: Record<string, any>) => void;
  onPullSuper: (id: string) => void;
  addAfter: (index: number) => void;
  disabled?: boolean;
}

export default function CollectionListItemEditRow(
  props: CollectionListItemEditProps
) {
  function removeMatch(idx: number): void  {
    let matches = props.item.superItems;
    matches.splice(idx, 1)
    props.updateItem(props.item.id, {superItems: matches})
  }

  return (
    <Card className={`CollectionListItemEditRow${props.item.flaggedForDelete ? ' marked-for-delete' : ''}`}>
      <div className="main-form">
        <div className="controls-box">
          <div className="index"># {props.index + 1}</div>
        </div>
        <div className="thumbnail-box">
          {props.item.superItems.length > 0 && (
            <ItemThumbnail item={props.item.superItems[0]} />
          )}
        </div>
        <div className="edit-form">
          <FormGroup label="Name" inline={true} className="form-field">
            <InputGroup
              fill={true}
              value={props.item.name}
              onChange={(e) =>
                props.updateItem(props.item.id, { name: e.target.value })
              }
            />
          </FormGroup>
          <fieldset className="matches">
            <legend>Matching Items</legend>
            {props.item.superItems.map((i, idx) => <div key={i.id} className="matching-item-row">
              <Button icon="trash" minimal={true} title="Remove from matches" intent="danger" onClick={() => removeMatch(idx)} />
              <Link to={`/collections/${props.collection.id}/items/${i.id}`} target={i.id}>{i.title}</Link>
            </div>)}
            {props.item.superItems.length === 0 && <span>(none)</span>}
          </fieldset>
          <div className="inner-controls">
            <Button icon="arrow-right" onClick={() => props.onPullSuper(props.item.id)} title="Add item from search pane into this item's matches">Pull</Button>
            <Button icon="trash" title="Mark this item for deletion" onClick={() => props.updateItem(props.item.id, {"flaggedForDelete": !props.item.flaggedForDelete})} intent="danger" minimal={true}/>
            {props.item.flaggedForDelete && <span>Flagged for deletion. Click to undo.</span>}
          </div>
        </div>
      </div>
      <div className="button-row">
        <Button icon="plus" onClick={() => props.addAfter(props.index)}>Add new item after</Button>
      </div>
    </Card>
  );
}
