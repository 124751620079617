import { Fragment, useState } from "react";
import { useAppDispatch } from "../../hooks";
import { addError } from "../../store";
import * as CollectionResource from "../../resources/collections";
import { Item } from "../../models";
import { Dialog, Classes, Button } from "@blueprintjs/core";

export default function ItemDelete(props: { collectionID: string, item: Item, onDelete: () => void }) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  function deleteItem(): void {
    setLoading(true);
    CollectionResource.deleteItem(props.collectionID, props.item.id).then(
      () => {
        setLoading(false);
        setOpen(false);
        props.onDelete();
      },
      (err) => dispatch(addError(err))
    );
  }

  return (
    <Fragment>
      <Button icon="trash" onClick={() => setOpen(true)} />
      <Dialog
        isOpen={open}
        onClose={() => setOpen(false)}
        title="Delete Item"
        transitionDuration={50}
      >
        <div className={Classes.DIALOG_BODY}>
          <p>
            Do you really want to delete <b>{props.item.title}</b>? This cannot
            be undone.
          </p>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => setOpen(false)} disabled={loading}>
              Cancel
            </Button>
            <Button
              intent="danger"
              onClick={() => deleteItem()}
              autoFocus={true}
              disabled={loading}
            >
              Confirm Delete
            </Button>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
}
