import { API_URL } from "../settings";
import * as AuthHelper from "../helpers/auth";

function getAuthToken(): string | null {
  return window.localStorage.authToken;
}

export async function coerceResp<T>(resp: Response) {
  if (resp.ok) {
    if (resp.status === 204) {
      // TODO something better than this
      return {} as T;
    }
    if (
      (resp.headers.get("content-type") || "").indexOf("application/json") !==
      -1
    ) {
      return resp.json();
    } else {
      // this really shouldn't happen
      return resp.text();
    }
  } else {
    return resp.json().then(obj => Promise.reject(obj));
  }
}

export async function authFetch<T>(
  method: string,
  route: string,
  data: any | null = null,
  config: Record<string, any> | null = null,
): Promise<T> {
  const conf = config || { headers: {} as Record<string, string> };
  if (!!data) {
    conf.body = JSON.stringify(data);
    conf.headers = {
      ...conf.headers,
      "Content-type": "application/json;charset=UTF-8",
    };
  } else {
    conf.headers = {...conf.headers};
  }
  if (conf.params) {
    const params = conf.params;
    delete conf["params"];
    route = `${route}?${(new URLSearchParams(params)).toString()}`;
  }
  const authToken = getAuthToken();
  if (!!authToken) {
    conf.headers.Authorization = `Bearer ${authToken}`;
  }
  conf.method = method;
  return fetch(API_URL + route, conf).then(
    resp => {
      if (!!authToken && resp.status === 401) {
        // attempt to refresh the token exactly once
        return AuthHelper.refreshToken().then(user => {
          if (!!user) {
            // update the config's headers so we don't slam into a wall lmfao
            conf.headers.Authorization = `Bearer ${getAuthToken()}`;
            return fetch(API_URL + route, conf).then(coerceResp)
          }
          return coerceResp(resp);
        });
      } else {
        return coerceResp(resp);
      }
    });
}
