import { ReactNode } from "react";
import { CollectionSchemaField, Item } from "../../../models";
import { FieldValue, ImageEntry } from "../../../models/item";

export type IntegrationValue = string | Record<string, any> | undefined;

export interface MutableItem {
  superItem: Item | undefined;
  title: string;
  quantity: number;
  fields: Record<string, FieldValue>;
  modifiers: Record<string, boolean>;
  images: Array<ImageEntry>;
}

export abstract class Integration<T = IntegrationValue> {
  abstract renderValue(
    field: CollectionSchemaField,
    value: T | undefined,
  ): ReactNode;

  renderFormField(
    item: MutableItem,
    field: CollectionSchemaField,
    value: T | undefined,
    updateValue: (value: IntegrationValue) => void
  ): ReactNode {
    return this.renderValue(field, value);
  }
}
