import "./ItemThumbnail.scss";
import { useState } from "react";
import { Overlay, Card, Classes, Button, Text } from "@blueprintjs/core";
import { imageURL } from "../../helpers/images";
import { Item } from "../../models";

interface ImageThumbnailProps {
  item: Item;
  index?: number;
  secondary?: Item;
  size?: number;
  minimal?: boolean;
}

export default function ImageThumbail(props: ImageThumbnailProps) {
  const [isOpen, setOpen] = useState(false);
  const index = props.index || 0;
  const [viewIndex, setViewIndex] = useState(index);
  const size = props.size || 100;
  let entries = [...props.item.images];
  if (props.secondary) {
    entries = entries.concat(props.secondary.images);
  }

  function navLeft() {
    setViewIndex(viewIndex > 0 ? viewIndex - 1 : entries.length - 1);
  }

  function navRight() {
    setViewIndex(viewIndex === entries.length - 1 ? 0 : viewIndex + 1);
  }

  return (
    <>
      {entries.length > 0 && (
        <>
          {props.minimal ? (
            <Button
              minimal={true}
              icon="camera"
              onClick={(e) => {e.stopPropagation(); setOpen(true); return false;}}
            />
          ) : (
            <div
              className="image-thumb"
              style={{ width: `${size}px`, height: `${size}px` }}
            >
              <img
                src={imageURL(entries[index], "small")}
                onClick={(e) => {e.stopPropagation(); setOpen(true); return false;}}
                alt="Thumbnail"
              />
            </div>
          )}
          <Overlay
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            transitionDuration={0}
          >
            <Card
              elevation={3}
              className={`overlay-card ItemThumbnail-modal ${Classes.OVERLAY_SCROLL_CONTAINER}`}
            >
              <div className="header">
                <Text className="title" ellipsize={true}>
                  {props.item.title}
                </Text>
                <Button
                  className="close-button"
                  icon="cross"
                  minimal={true}
                  title="Close"
                  intent="danger"
                  onClick={(e) => {e.stopPropagation(); setOpen(false); return false;}}
                />
              </div>
              <div className="main-image">
                <Button
                  className="nav-button"
                  icon="arrow-left"
                  onClick={navLeft}
                />
                <div className="image-full">
                  <img
                    src={imageURL(entries[viewIndex], "full")}
                    onClick={navRight}
                    alt="Full view"
                  />
                </div>
                <Button
                  className="nav-button"
                  icon="arrow-right"
                  onClick={navRight}
                />
              </div>
            </Card>
          </Overlay>
        </>
      )}
    </>
  );
}
