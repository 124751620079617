import { CollectionSchemaField } from "../../models";
import { getIntegration, IntegrationValue, MutableItem } from "./integrations";

interface IntegrationFormFieldProps {
  item: MutableItem;
  field: CollectionSchemaField;
  value: IntegrationValue;
  editing?: boolean;
  updateValue?: (value: IntegrationValue) => void;
}

export function IntegrationFormField(props: IntegrationFormFieldProps): any {
  const integration = getIntegration(props.field.integration!);

  return props.editing ? integration.renderFormField(props.item, props.field, props.value, props.updateValue!) : integration.renderValue(props.field, props.value);
}
