import { Integration } from "./base";
import { IGDBIntegration } from "./igdb";
import { GoogleBooksIntegration } from "./googlebooks";
import { GradingIntegration } from "./grading";
import { TCGPlayerIntegration } from "./tcgplayer";
import { NullIntegration } from "./null";

const integrations: Record<string, Integration> = {
  igdb: new IGDBIntegration(),
  googlebooks: new GoogleBooksIntegration(),
  grading: new GradingIntegration(),
  tcgplayer: new TCGPlayerIntegration(),
};

export function getIntegration(name: string): Integration {
  const found = integrations[name];
  if (found) {
    return found;
  }
  return new NullIntegration();
}

export type { IntegrationValue, MutableItem } from "./base";
