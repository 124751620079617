import { useState } from "react";
import { CollectionWithSuper, CollectionSchemaField } from "../../models";
import {
  Collapse,
  Card,
  ControlGroup,
  HTMLSelect,
  InputGroup,
  Button,
} from "@blueprintjs/core";

export default function FilterBuilder(props: {
  collection: CollectionWithSuper;
  open: boolean;
  addFilter: (key: string, value: Array<string | number>) => void;
  childView: boolean;
}) {
  const fields = [
    { value: "title", label: "Title" },
  ].concat(props.childView ? [] : 
    [{ value: "quantity", label: "Quantity" }]
  ).concat(
    props.collection.schema.fields.map((f: CollectionSchemaField) => ({
      value: `${props.childView ? "child." : ""}field.${f.name}`,
      label: f.display,
    })),
    (props.collection.super_collection?.schema.fields.map(
      (f: CollectionSchemaField) => ({
        value: `${props.childView ? "" : "super."}field.${f.name}`,
        label: f.display,
      })
    ) || [])
  );

  const operators = [
    { value: "eq", label: "is" },
    { value: "ne", label: "is not" },
    { value: "lt", label: "<" },
    { value: "gt", label: ">" },
    { value: "in", label: "is any of" },
    { value: "nin", label: "is none of" },
  ];

  const [field, setField] = useState(fields[0].value);
  const [operator, setOperator] = useState(operators[0].value);
  const [value, setValue] = useState("");

  function filterIsValid(): boolean {
    if (!value) {
      return false;
    }
    return true;
  }

  function doAdd(): void {
    const v = ["in", "nin"].includes(operator) ? value.split(",").map(s => s.trim()) : [value];
    props.addFilter(field, [operator, ...v]);
    setField(fields[0].value);
    setOperator(operators[0].value);
    setValue("");
  }

  return (
    <Collapse isOpen={props.open}>
      <Card>
        <ControlGroup>
          <HTMLSelect
            options={fields}
            value={field}
            onChange={(e) => setField(e.target.value)}
          />
          <HTMLSelect
            options={operators}
            value={operator}
            onChange={(e) => setOperator(e.target.value)}
          />
          <InputGroup
            value={value}
            placeholder={["in", "nin"].includes(operator) ? "Comma-separated values" : "Value"}
            onChange={(e) => setValue(e.target.value)}
          />
          <Button
            intent="primary"
            disabled={!filterIsValid()}
            onClick={() => doAdd()}
          >
            Add Filter
          </Button>
        </ControlGroup>
      </Card>
    </Collapse>
  );
}
