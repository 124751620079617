import { authFetch } from "./util";
import {
  Collection,
  CollectionSummary,
  CollectionDetail,
  CollectionWithSuper,
  CollectionSchema,
  ItemWithSuper,
  MinimalItem,
  Item,
  PublicUser,
  ItemWithChildren,
  ImageEntry,
} from "../models";

export async function recentCollections(): Promise<Array<CollectionSummary>> {
  return authFetch("GET", "/api/collections/recent");
}

export interface CollectionWithOwner extends Collection {
  owner_obj: PublicUser;
}

export async function listCollections(params: {
  page: number,
  perPage: number,
  sort: string,
  sortAsc: boolean
}): Promise<{count: number, collections: Array<CollectionWithOwner>}> {
  return authFetch("GET", "/api/collections", null, {params: params});
}

export async function listCollectionsForUser(
  userID: string,
  includeSchema: boolean = false
): Promise<Array<CollectionDetail>> {
  return authFetch("GET", `/api/users/${userID}/collections`, null, {
    params: { includeSchema: includeSchema },
  });
}

export async function getCollectionDetail(
  collectionID: string
): Promise<{ collection: CollectionWithSuper; owner: PublicUser }> {
  return authFetch("GET", `/api/collections/${collectionID}`);
}

interface CollectionMutableFields {
  name: string;
  template: string | undefined;
  public: boolean;
  archived: boolean;
  schema: CollectionSchema;
}

export async function createCollection(
  collection: CollectionMutableFields
): Promise<CollectionDetail> {
  return authFetch("POST", "/api/collections", collection);
}

export async function updateCollection(
  collectionID: string,
  collection: CollectionMutableFields
): Promise<CollectionDetail> {
  return authFetch("PUT", `/api/collections/${collectionID}`, collection);
}

export async function deleteCollection(collectionID: string): Promise<void> {
  return authFetch("DELETE", `/api/collections/${collectionID}`);
}

export async function getItems(
  collectionID: string,
  settings: {
    filters?: any;
    page?: number;
    perPage?: number;
    sortBy?: string;
    sortDir?: string;
    childView?: boolean;
  } = {}
): Promise<{ count: number; items: Array<ItemWithSuper | ItemWithChildren> }> {
  let params = { ...settings } as Record<string, any>;
  if (settings.filters) {
    params.filters = JSON.stringify(settings.filters);
  }
  if (params.childView) {
    delete params["childView"];
    params["reverse"] = true;
  }
  return authFetch("GET", `/api/collections/${collectionID}/items`, null, {
    params,
  });
}

export async function getItemsFilteredByTitle(
  collectionID: string,
  title: string
): Promise<Array<MinimalItem>> {
  return authFetch("GET", `/api/collections/${collectionID}/filter`, null, {
    params: { value: title },
  });
}

export async function getItem(
  collectionID: string,
  itemID: string
): Promise<{ item: ItemWithSuper }> {
  return authFetch(
    "GET",
    `/api/collections/${collectionID}/items/${itemID}`,
    null,
    { params: { fullDetail: "false" } }
  );
}

export async function getItemDetail(
  collectionID: string,
  itemID: string,
  childCollectionID?: string
): Promise<{
  item: ItemWithSuper | ItemWithChildren;
  collection: CollectionWithSuper;
  childCollection: CollectionDetail | undefined;
  owner: PublicUser;
}> {
  const params = { fullDetail: "true" } as Record<string, string>;
  if (childCollectionID) {
    params["childrenFrom"] = childCollectionID;
  }
  return authFetch(
    "GET",
    `/api/collections/${collectionID}/items/${itemID}`,
    null,
    { params: params }
  );
}

export async function addItem(
  collectionID: string,
  newItem: {
    super_item?: string;
    title: string;
    quantity: number;
    fields: Record<string, any>;
    modifiers: Array<string>;
    images: Array<ImageEntry>;
  }
): Promise<ItemWithSuper> {
  let newItemFiltered = { ...newItem } as Record<string, any>;
  delete newItemFiltered["super_item"];
  const body = {
    super_item: newItem.super_item,
    ...newItemFiltered,
  };
  return authFetch("POST", `/api/collections/${collectionID}/items`, body);
}

export async function updateItem(
  collectionID: string,
  item: Item
): Promise<Item> {
  return authFetch(
    "PUT",
    `/api/collections/${collectionID}/items/${item.id}`,
    item
  );
}

export async function deleteItem(
  collectionID: string,
  itemID: string
): Promise<void> {
  return authFetch(
    "DELETE",
    `/api/collections/${collectionID}/items/${itemID}`
  );
}

export async function importItems(
  collectionID: string,
  items: Array<{
    title: string;
    fields?: Record<string, any>;
    image?: string;
  }>
): Promise<{ count: number; messages: Array<string> }> {
  return authFetch("POST", `/api/collections/${collectionID}/import`, {
    items: items,
  });
}
