import "./CollectionLists.scss";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { setCrumbs, clearCrumbs, addError } from "../../store";
import { useAppDispatch } from "../../hooks";
import { Card } from "@blueprintjs/core";
import {
  CollectionListMutableCreate,
  CollectionWithSuper,
  PublicUser,
} from "../../models";
import { CollectionResource, ListResource } from "../../resources";
import Loader from "../shared/Loader";
import CollectionListForm from "../lists/CollectionListForm";

export default function CollectionListCreate() {
  const dispatch = useAppDispatch();
  const params = useParams<{ collectionID: string }>();
  const history = useHistory();
  const [state, setState] = useState({
    loading: true,
    collection: {} as CollectionWithSuper,
    owner: {} as PublicUser,
  });
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    CollectionResource.getCollectionDetail(params.collectionID).then(
      ({ collection, owner }) => {
        setState({
          loading: false,
          collection: collection,
          owner: owner,
        });
        dispatch(
          setCrumbs({
            title: `${collection.name} - Create List`,
            items: [
              { to: `/users/${owner.id}`, text: owner.username },
              {
                to: `/collections/${collection.id}`,
                text: collection.name,
              },
              "New List",
            ],
          })
        );
      },
      (err) => dispatch(addError(err))
    );
    return () => {
      dispatch(clearCrumbs());
    };
  }, [dispatch, params.collectionID]);

  function saveList(list: CollectionListMutableCreate): void {
    setSaving(true);
    ListResource.createList(state.collection.id, list).then(newList => {
      history.push(`/collections/${state.collection.id}/lists/${newList.id}`);
    }, err => {
      setSaving(false);
      dispatch(addError(err));
    });
  }

  function cancel(): void {
    history.goBack();
  }

  return <Card className="Page CollectionListCreate">
    {state.loading ? <Loader /> : <>
      <CollectionListForm
        collection={state.collection}
        disabled={saving}
        onCreate={saveList}
        onCancel={cancel}
      />
    </>}
  </Card>;
}
