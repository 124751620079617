import { authFetch } from "./util";

interface IntegrationLookup {
  error?: string;
  result?: Record<string, any>;
}

export function queryIntegration(
  name: string,
  value: string | Record<string, any>
): Promise<IntegrationLookup> {
  return authFetch("POST", `/api/integrations/${name}`, { value: value });
}
