import "./ItemListRow.scss";
import { Link, useHistory } from "react-router-dom";
import { ButtonGroup, HTMLTable } from "@blueprintjs/core";
import {
  CollectionWithSuper,
  CollectionSchemaField,
  ItemWithSuper,
  ItemWithChildren,
} from "../../models";
import ISODate from "../shared/ISODate";
import ItemDelete from "./ItemDelete";
import ItemThumbnail from "./ItemThumbnail";
import { getIntegration } from "./integrations";

interface ItemListRowProps {
  collection: CollectionWithSuper;
  childView: boolean;
  item: ItemWithSuper | ItemWithChildren;
  minimal?: boolean;
  showControls?: boolean;
  onDelete?: () => void;
}

export default function ItemListRow(props: ItemListRowProps) {
  const history = useHistory();
  const minimal = props.minimal === true;

  // typescript tomfoolery
  const itemSuper = props.item as ItemWithSuper;
  const itemChild = props.item as ItemWithChildren;

  function renderValue(field: CollectionSchemaField, value: any): any {
    if (field.type === "integration") {
      const integration = getIntegration(field.integration!);
      return integration.renderValue(field, value);
    } else if (field.type === "boolean") {
      if (value === true) {
        return "true";
      }
      if (value === false) {
        return "false";
      }
    }
    return value;
  }

  function itemCount(item: ItemWithChildren): number {
    return item.children?.reduce((total, i) => i.quantity - 0 + total, 0);
  }

  return (
    <>
      <tr className="item-row">
        {!props.childView && props.showControls && (
          <td>
            <ButtonGroup minimal={true}>
              {/* <Button icon="edit" /> */}
              <ItemDelete
                collectionID={props.collection.id}
                item={props.item}
                onDelete={props.onDelete || (() => {})}
              />
            </ButtonGroup>
          </td>
        )}
        <td rowSpan={props.childView ? 2 : 1}>
          <ItemThumbnail
            item={props.item}
            secondary={(props.item as ItemWithSuper).super_item_detail}
            size={100}
            minimal={minimal}
          />
        </td>
        <td rowSpan={props.childView ? 2 : 1}>
          {props.childView ? itemCount(itemChild) : props.item.quantity}
        </td>
        <td>
          <Link
            to={`/collections/${
              !props.childView
                ? props.collection.id
                : props.collection.super_collection!.id
            }/items/${props.item.id}${props.childView ? '?child=' + props.collection.id : ''}`}
          >
            {props.item.title}
          </Link>
        </td>
        {!props.childView &&
          props.collection.schema.fields.map((f: CollectionSchemaField) => (
            <td key={f.name}>{renderValue(f, props.item.fields[f.name] || "")}</td>
          ))}
        {props.collection.super_collection &&
          props.collection.super_collection.schema.fields.map(
            (f: CollectionSchemaField) => (
              <td key={f.name}>
                {renderValue(
                  f,
                  itemSuper.super_item_detail?.fields[f.name] ||
                    props.item.fields[f.name] ||
                    ""
                )}
              </td>
            )
          )}
        {!props.childView && (
          <>
            <td>
              {props.item.modifiers.map((m) => (
                <span key={m} className="modifier">
                  {m}
                </span>
              ))}
            </td>
            <td><ISODate timestamp={props.item.added} /></td>
          </>
        )}
      </tr>
      {props.childView && (
        <tr className="item-row child-row">
          <td
            colSpan={
              1 + props.collection.super_collection!.schema.fields.length
            }
          >
            <HTMLTable
              className="child-table"
              striped={true}
              condensed={true}
              bordered={true}
              interactive={true}
            >
              <tbody>
                <tr>
                  <th>Qty</th>
                  {props.collection.schema.fields.map(
                    (f: CollectionSchemaField) => (
                      <th key={f.name}>{f.display}</th>
                    )
                  )}
                  <th>Modifiers</th>
                  <th>Added</th>
                </tr>
                {itemChild.children.map((c) => (
                  <tr key={c.id} onClick={() => history.push(`/collections/${props.collection.id}/items/${c.id}`)}>
                    <td>{c.quantity}</td>
                    {props.collection.schema.fields.map((f) => (
                      <td key={f.name}>{renderValue(f, c.fields[f.name] || "")}</td>
                    ))}
                    <td>
                      {c.modifiers.map((m) => (
                        <span key={m} className="modifier">
                          {m}
                        </span>
                      ))}
                    </td>
                    <td><ISODate timestamp={c.added} /></td>
                  </tr>
                ))}
              </tbody>
            </HTMLTable>
          </td>
        </tr>
      )}
    </>
  );
}
