import { useState } from "react";
import { useAppDispatch } from "../../hooks";
import { addError } from "../../store";
import { FileInput } from "@blueprintjs/core";
import * as ImageResource from "../../resources/images";
import * as ImageHelper from "../../helpers/images";

interface ItemImageFieldProps {
  disabled: boolean;
  onImageAdd: (token: string) => void;
  fill?: boolean;
}

const maxImageSize = 2000;

export default function ItemImageField(props: ItemImageFieldProps) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  function resizeAndUpload(file?: File): void {
    // the user hitting "cancel" will trigger onChange with [undefined] as the file list
    if (file) {
      setLoading(true)
      resizeImage(file)
        .then((b64Data) =>
          ImageResource.uploadImage(b64Data).then((resp) => {
            props.onImageAdd(resp.token);
          })
        )
        .catch((err) => dispatch(addError(err)))
        .finally(() => setLoading(false));
    }
  }

  function resizeImage(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      ImageHelper.resizeImageFile(file, maxImageSize, resolve, reject);
    });
  }

  return (
    <>
      <FileInput
        disabled={props.disabled || loading}
        fill={props.fill}
        inputProps={{
          accept: "image/jpeg,image/png",
          value: undefined,
          onChange: (e) => {
            e.preventDefault();
            (e.target as any).files && resizeAndUpload((e.target as any).files[0]);
          },
          onDragOver: (e) => e.preventDefault(),
          onDragEnter: (e) => e.preventDefault(),
        }}
      />
    </>
  );
}
