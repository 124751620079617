export class QueryParams {
  sp: URLSearchParams;

  constructor(locationString: string) {
    this.sp = new URLSearchParams(locationString);
  }

  get(key: string, def?: string): string | typeof def {
    if (this.sp.has(key)) {
      return this.sp.get(key)!;
    }
    return def;
  }

  getInt(key: string, def?: number): number | typeof def {
    if (this.sp.has(key)) {
      const val = this.sp.get(key);
      if (val != null) {
        try {
          return parseInt(val);
        } catch {
        }
      }
    }
    return def;
  }

  getBool(key: string, def: boolean): boolean;
  getBool(key: string, def?: boolean | undefined): boolean | undefined {
    if (this.sp.has(key)) {
      const val = this.sp.get(key);
      if (val === "true") {
        return true;
      }
      if (val === "false") {
        return false;
      }
    }
    return def;
  }

  getJSON<T>(key: string, def: T): T {
    if (this.sp.has(key)) {
      try {
        return JSON.parse(this.sp.get(key)!);
      } catch (e) {
        console.warn(`Unable to parse ${key} param as JSON`);
      }
    }
    return def;
  }

  encode(extra?: Record<string, any>): string {
    const nsp = new URLSearchParams(this.sp);
    if (extra) {
      Object.entries(extra).map(([k, v]) => nsp.set(k, v));
    }
    return nsp.toString();
  }
}
