import { useState } from "react";
import { Collection, MinimalItem, Item } from "../../models";
import * as CollectionResource from "../../resources/collections";
import { MenuItem } from "@blueprintjs/core";
import { Suggest } from "@blueprintjs/select";
import { useAppDispatch } from "../../hooks";
import { addError } from "../../store";

interface SuggestProps {
  suggestFrom: Collection;
  onSelect: (item?: Item) => void;
  selectedItem?: Item;
  disabled?: boolean;
  fill?: boolean;
  placeholder?: string;
}

export default function SuperItemSuggest(props: SuggestProps) {
  const dispatch = useAppDispatch();
  const [suggestions, setSuggestions] = useState([] as Array<MinimalItem>);
  const [selectedItem, setSelectedItem] = useState(props.selectedItem);

  function loadSuggestions(value: string, event: any): void {
    if (event) {
      if (value && value.length > 2) {
        CollectionResource.getItemsFilteredByTitle(
          props.suggestFrom.id,
          value
        ).then(setSuggestions, (err) => dispatch(addError(err)));
      } else {
        setSuggestions([]);
        if (value === "" || value === undefined) {
          // then clear out the super item
          setSelected(undefined);
        }
      }
    }
  }

  function renderSuggestionItem(
    item: MinimalItem,
    props: { handleClick: any; modifiers: any; query: any }
  ) {
    return (
      <MenuItem
        key={item.id}
        active={props.modifiers.active}
        onClick={props.handleClick}
        text={item.title}
      />
    );
  }

  function setSuperItem(item: MinimalItem, e: any): void {
    e.preventDefault();
    CollectionResource.getItem(props.suggestFrom.id, item.id).then(
      ({ item }) => setSelected(item),
      (err) => dispatch(addError(err))
    );
    return;
  }

  function setSelected(item?: Item): void {
    props.onSelect(item);
    setSelectedItem(item);
  }

  return <Suggest
    inputProps={{placeholder: props.placeholder}}
    disabled={props.disabled}
    fill={true}
    selectedItem={selectedItem}
    popoverProps={{ minimal: true }}
    inputValueRenderer={(item) => {
      return item.title;
    }}
    itemRenderer={renderSuggestionItem}
    items={suggestions}
    onQueryChange={loadSuggestions}
    noResults={<i>No results</i>}
    onItemSelect={setSuperItem}
    closeOnSelect={true}
  />;
}
