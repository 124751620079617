import { useEffect } from "react";
import {
  getCurrentUser,
  setCurrentUser,
  setCrumbs,
  clearCrumbs,
  addError,
} from "../../store";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Card, Button } from "@blueprintjs/core";
import * as AuthHelper from "../../helpers/auth";
import * as AuthResource from "../../resources/auth";

export default function Settings() {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(getCurrentUser);

  useEffect(() => {
    dispatch(
      setCrumbs({
        title: "Settings",
        items: ["Settings"],
      })
    );
    return () => {
      dispatch(clearCrumbs());
    };
  }, [dispatch]);

  function assumeTemplateUser(): void {
    AuthResource.assumeUserSession("templates").then(
      (resp) => {
        window.localStorage.setItem("authToken", resp.token);
        dispatch(setCurrentUser(AuthHelper.userFromJWT()));
      },
      (err) => {
        dispatch(addError(err));
      }
    );
  }

  function doRefresh(): void {
    AuthHelper.refreshToken().then(
      (user) => {
        if (user) {
          console.log("Successfully refreshed");
        } else {
          console.log("Failed miserably");
        }
      },
      (err) => dispatch(addError(err))
    );
  }

  return (
    <Card className="Settings">
      {currentUser ? (
        <>
          {currentUser.admin && (
            <>
              <Button
                icon="circle-arrow-up"
                onClick={(e) => assumeTemplateUser()}
              >
                Assume Template User
              </Button>
              <Button icon="refresh" onClick={() => doRefresh()}>
                Test Refresh Token
              </Button>
            </>
          )}
          Oh hey, it's the settings page
        </>
      ) : (
        "Hmm..."
      )}
    </Card>
  );
}
