import { ReactNode } from "react";
import { CollectionSchemaField } from "../../../models";
import { Integration, IntegrationValue } from "./base";

export class NullIntegration extends Integration {
  renderValue(
    field: CollectionSchemaField,
    value: IntegrationValue
  ): ReactNode {
    return typeof value === "string" ? value : JSON.stringify(value);
  }
}
