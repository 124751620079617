import "./CollectionListEditSearch.scss";
import {
  Button,
  Callout,
  Card,
  ControlGroup,
  HTMLTable,
  Icon,
} from "@blueprintjs/core";
import { CollectionDetail, CollectionSchemaField, Item } from "../../models";
import SuperItemSuggest from "../collections/SuperItemSuggest";
import ItemThumbnail from "../collections/ItemThumbnail";

interface CollectionListEditSearchProps {
  collection: CollectionDetail;
  selectedItem?: Item;
  onSelect: (item?: Item) => void;
}

export default function CollectionListEditSearch(
  props: CollectionListEditSearchProps
) {
  function stringify(field: CollectionSchemaField): string {
    const value = props.selectedItem?.fields[field.name];
    if (value === undefined || value == null) {
      return "";
    }
    if (field.type === "boolean") {
      return value ? "True" : "False";
    }
    if (["string", "number", "choice"].includes(field.type)) {
      return value.toString();
    }
    return JSON.stringify(value);
  }

  return (
    <Card className="CollectionListEditSearch">
      <ControlGroup>
        <SuperItemSuggest
          suggestFrom={props.collection}
          onSelect={props.onSelect}
          selectedItem={props.selectedItem}
        />
        <Button icon="cross" onClick={() => props.onSelect(undefined)} />
      </ControlGroup>
      {props.selectedItem ? (
        <Callout className="search-callout">
          <div className="image-box">
            {props.selectedItem.images.length > 0 ? (
              <ItemThumbnail item={props.selectedItem} />
            ) : (
              <Icon icon="camera" />
            )}
          </div>
          <HTMLTable condensed={true} className="item-details">
            <tr>
              <th colSpan={2}>{props.selectedItem.title}</th>
            </tr>
            {props.collection.schema.fields.map((f) => (
              <tr key={f.name}>
                <th>{f.display}</th>
                <td>{stringify(f)}</td>
              </tr>
            ))}
          </HTMLTable>
        </Callout>
      ) : (
        <div className="initial-help">
          Use the search box above to find items to add to this list.
        </div>
      )}
    </Card>
  );
}
