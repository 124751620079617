import "./CollectionListItemsEdit.scss";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { setCrumbs, clearCrumbs, addError } from "../../store";
import { useAppDispatch } from "../../hooks";
import { Button, Card } from "@blueprintjs/core";
import {
  CollectionList,
  CollectionListItem,
  CollectionListItemMutable,
  CollectionListItemWithMatches,
  CollectionWithSuper,
  Item,
  PublicUser,
} from "../../models";
import { CollectionResource, ListResource } from "../../resources";
import Loader from "../shared/Loader";
import CollectionListItemEditRow, {
  EditableCollectionListItem,
} from "../lists/CollectionListItemEditRow";
import CollectionListEditSearch from "../lists/CollectionListEditSearch";

function convertToEditable(
  item: CollectionListItemWithMatches
): EditableCollectionListItem {
  return {
    id: item.id,
    name: item.name,
    superItems: item.matching,
    original: item,
    flaggedForDelete: false,
    requiredModifiers: item.required_modifiers,
    ordering: item.ordering,
  };
}

export default function CollectionListItemsEdit() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const params = useParams<{ collectionID: string; listID: string }>();
  const [state, setState] = useState({
    loading: true,
    collection: {} as CollectionWithSuper,
    owner: {} as PublicUser,
    list: {} as CollectionList,
    items: [] as Array<EditableCollectionListItem>,
  });
  const [selectedItem, setSelectedItem] = useState(undefined as Item | undefined);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    CollectionResource.getCollectionDetail(params.collectionID).then(
      ({ collection, owner }) => {
        ListResource.listItems(params.collectionID, params.listID).then(
          ({ list, items }) => {
            let convertedItems = items.map((i) => convertToEditable(i));
            // if the first two items have the same ordering, assume the entire collection needs to be reflowed
            if (
              convertedItems.length >= 2 &&
              convertedItems[0].ordering === convertedItems[1].ordering
            ) {
              convertedItems.forEach((item, index) => {
                item.ordering = index;
              });
            } else if (convertedItems.length === 0) {
              convertedItems.push(makeItem());
            }
            setState({
              loading: false,
              collection: collection,
              owner: owner,
              list: list,
              items: convertedItems,
            });
            dispatch(
              setCrumbs({
                title: `${collection.name} - Edit List`,
                items: [
                  { to: `/users/${owner.id}`, text: owner.username },
                  {
                    to: `/collections/${collection.id}`,
                    text: collection.name,
                  },
                  {
                    to: `/collections/${collection.id}/lists/${list.id}`,
                    text: list.name,
                  },
                  "Edit Items",
                ],
              })
            );
          },
          (err) => dispatch(addError(err))
        );
      },
      (err) => dispatch(addError(err))
    );
    return () => {
      dispatch(clearCrumbs());
    };
  }, [dispatch, params.collectionID, params.listID]);

  function updateItem(id: string, update: Record<string, any>): void {
    setState({
      ...state,
      items: state.items.map((i) => {
        if (i.id === id) {
          return { ...i, ...update };
        }
        return i;
      }),
    });
  }

  function makeItem(): EditableCollectionListItem {
    return {
      id: uuid(),
      name: "",
      superItems: [],
      original: null,
      flaggedForDelete: false,
      requiredModifiers: [],
      ordering: 0,
    };
  }

  function addNewItem(afterIndex: number): void {
    const newItem = makeItem();

    if (state.items.length === 0) {
      setState({
        ...state,
        items: [newItem],
      });
    } else {
      let newItems: Array<EditableCollectionListItem> = [];
      state.items.forEach((i, index) => {
        if (index > afterIndex) {
          i.ordering += 1;
        }
        newItems.push(i);
        if (index === afterIndex) {
          newItem.ordering = index;
          newItems.push(newItem);
        }
      });
      setState({
        ...state,
        items: newItems,
      });
    }
  }

  function pullSuper(id: string): void {
    if (selectedItem) {
      const item = state.items.find(i => i.id === id);
      if (item && !item.superItems.includes(selectedItem)) {
        const u: Record<string, any> = {superItems: [...item.superItems, selectedItem]};
        if (item.name === "") {
          u["name"] = selectedItem.title;
        }
        updateItem(id, u);
      }
    }
  }

  const allItemsValid = state.items.filter(i => !i.flaggedForDelete && ( i.name === "" || i.superItems.length === 0)).length === 0;

  function doCancel(): void {
    history.goBack();
  }

  function isDirty(item: EditableCollectionListItem): boolean {
    if (!item.original) {
      throw new Error("How did you get here?");
    }
    return item.ordering !== item.original.ordering || // most likely - check this first
      item.name !== item.original.name ||
      !arraysEqual(item.superItems.map(i => i.id), item.original.matching_super_items) ||
      !arraysEqual(item.requiredModifiers, item.original.required_modifiers);
  }

  function arraysEqual(a1: Array<string>, a2: Array<string>): boolean {
    return a1.length === a2.length &&
      a1.filter(a => !a2.includes(a)).length === 0;
  }

  function categorizeItemActions(): [Array<CollectionListItemMutable>, Array<CollectionListItem>, Array<string>] {
    const newItems: Array<CollectionListItemMutable> = [];
    const updatedItems: Array<CollectionListItem> = [];
    const deletedItems: Array<string> = [];
    state.items.forEach(item => {
      if (item.flaggedForDelete) {
        // if no original, this was added and then marked as deleted - don't do anything
        if (item.original) {
          console.debug(`Deleting "${item.name}"`, item);
          deletedItems.push(item.id);
        }
      } else if (!item.original) {
        // then this is a new item
        console.debug(`Adding "${item.name}"`, item);
        newItems.push({
          name: item.name,
          matching_super_items: item.superItems.map(i => i.id),
          required_modifiers: item.requiredModifiers,
          ordering: item.ordering,
        });
      } else if (isDirty(item)) {
        // then this should be updated
        console.debug(`Updating "${item.name}"`, item, item.original);
        updatedItems.push({
          id: item.id,
          name: item.name,
          matching_super_items: item.superItems.map(i => i.id),
          required_modifiers: item.requiredModifiers,
          ordering: item.ordering,
        });
      }
    });
    return [newItems, updatedItems, deletedItems];
  }

  function doSave(): void {
    setSaving(true);
    const [newItems, updatedItems, deletedItems] = categorizeItemActions();
    console.debug(`Expected counts: ${newItems.length}, ${updatedItems.length}, ${deletedItems.length}`)
    ListResource.patchListItems(state.collection.id, state.list.id, newItems, updatedItems, deletedItems).then(
      (resp) => {
        console.log(`Actual counts: ${resp.new}, ${resp.updated}, ${resp.deleted}`);
        history.push(`/collections/${state.collection.id}/lists/${state.list.id}`);
      }, err => {
        setSaving(false);
        dispatch(addError(err));
      });
  }

  return (
    <div className="Page CollectionListItemsEdit">
      <div id="search-pane">
        <CollectionListEditSearch
          collection={state.list.is_template ? state.collection : state.collection.super_collection || state.collection}
          onSelect={setSelectedItem}
          selectedItem={selectedItem}
          />
        <Card className="summary-box">
          <div className="count-summary">
            {state.items.length} items in list.
          </div>
          <div className="summary-buttons">
            <Button icon="floppy-disk" intent="success" disabled={saving || !allItemsValid} onClick={() => doSave()}>Save Changes</Button>
            <Button disabled={saving} onClick={doCancel}>Cancel</Button>
          </div>
        </Card>
      </div>
      <div id="items-pane">
        {state.loading ? (
          <Loader />
        ) : (
          <>
            {state.items.map((i, index) => (
              <CollectionListItemEditRow
                key={i.id}
                index={index}
                collection={state.list.is_template ? state.collection : state.collection.super_collection || state.collection}
                item={i}
                updateItem={updateItem}
                addAfter={addNewItem}
                onPullSuper={pullSuper}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
}
