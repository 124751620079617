import "./Home.scss";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../shared/Loader";
import { addError, getCurrentUser } from "../../store";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Collection, CollectionSummary } from "../../models";
import * as CollectionResource from "../../resources/collections";
import { Card, Icon } from "@blueprintjs/core";
import UserCollectionList from "../collections/UserCollectionList";

export default function Home() {
  const [recentState, setRecentState] = useState({
    loading: true,
    recentCollections: [] as Array<CollectionSummary>,
  });
  const [userState, setUserState] = useState({
    loading: true,
    userCollections: [] as Array<Collection>,
  });
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(getCurrentUser);

  useEffect(() => {
    CollectionResource.recentCollections().then(
      (c) =>
        setRecentState({
          loading: false,
          recentCollections: c,
        }),
      (err) => dispatch(addError(err))
    );
    if (!!currentUser) {
      CollectionResource.listCollectionsForUser(currentUser.id).then(
      (c) =>
        setUserState({
          loading: false,
          userCollections: c,
        }),
        err => dispatch(addError(err))
      );
    }
  }, [dispatch, currentUser]);

  return (
    <div className="Page Home">
      <Card className="card">
        {!currentUser ? <>
          <header>What is this?</header>
          <p>
            This is a tool with which to manage your stuff and/or things in a very flexible (maybe too flexible) way. Many features planned, more being added frequently. For now, if you'd like to mess around, click the login button in the upper right.
          </p>
        </> : <>
          <header>Your Collections</header>
          <UserCollectionList collections={userState.userCollections} loading={userState.loading} self={true} />
        </>}
      </Card>
      <Card className="card">
        <header>Recent Collection Updates</header>
        {recentState.loading ? <Loader /> :
        <ul>
          {recentState.recentCollections.map((c) => (
            <li key={c.collectionID}>
              <Link to={`/users/${c.ownerID}`}>{c.ownerName}</Link>
              {" / "}
              <Link to={`/collections/${c.collectionID}`}>{c.collectionName}</Link>
            </li>
          ))}
        </ul>}
        <Link to="/collections">Browse Collections <Icon icon="arrow-right" /></Link>
      </Card>
    </div>
  );
}
