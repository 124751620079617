import { Button, ControlGroup, HTMLSelect, InputGroup, NumericInput } from "@blueprintjs/core";
import { ReactNode } from "react";
import { CollectionSchemaField } from "../../../models";
import { Integration, IntegrationValue, MutableItem } from "./base";
import * as IntegrationResource from "../../../resources/integrations";

interface Grade {
  grader?: string;
  cert?: string;
  grade?: number;
  metadata?: Record<string, any>;
}

function asGrade(obj: Record<string, any>): Grade {
  return {
    grader: obj["grader"],
    cert: obj["cert"] || "",
    grade: obj["grade"] || 0,
    metadata: obj["metadata"],
  };
}

function verificationURL(grader?: string, cert?: string): string | null {
  if (!grader || !cert) {
    return null;
  }
  if (grader.toLowerCase() === "psa") {
    return `https://www.psacard.com/cert/${cert}`;
  }
  if (grader.toLowerCase() === "cgc") {
    return `https://www.cgccomics.com/certlookup/${cert}/`;
  }
  if (["bgs", "bvg", "bccg"].includes(grader.toLowerCase())) {
    // beckett is a piece of shit
    return "https://www.beckett.com/grading/card-lookup";
  }
  return null;
}

export class GradingIntegration extends Integration {
  renderValue(
    field: CollectionSchemaField,
    value: IntegrationValue
  ): ReactNode {
    if (!value) {
      return "";
    }
    const coercedValue: Grade =
      typeof value === "string" ? { cert: value } : asGrade(value);
    const url = verificationURL(coercedValue.grader, coercedValue.cert);
    const label = `${coercedValue.grader || ""} ${coercedValue.grade || ""}`;
    if (!url) {
      return label;
    }
    return <a href={url} target="_blank" rel="noreferrer">{label}</a>;
  }



  renderFormField(
    item: MutableItem,
    field: CollectionSchemaField,
    value: IntegrationValue,
    updateValue: (value: IntegrationValue) => void,
  ): ReactNode {
    const coercedValue: Grade =
      (!value || typeof value === "string") ? asGrade({ cert: value }) : asGrade(value as Record<string, any>);
    return <ControlGroup>
      <HTMLSelect value={coercedValue.grader} onChange={e => updateValue({...coercedValue, grader: e.currentTarget.value})}>
        <option value={undefined} />
        <option value="PSA">PSA</option>
        <option value="CGC">CGC</option>
        <option value="BGS">BGS</option>
        <option value="BCCG">BCCG</option>
      </HTMLSelect>
      <InputGroup
        className="shrinkfix growfix-2"
        fill={false}
        value={coercedValue.cert}
        placeholder="Cert #"
        onChange={e => updateValue({...coercedValue, cert: e.target.value})} />
      <NumericInput
        className="shrinkfix"
        value={coercedValue.grade}
        stepSize={0.5}
        onValueChange={n => updateValue({...coercedValue, grade: n})} />
      <Button
        icon="search"
        disabled={!coercedValue.grader || !coercedValue.cert}
        onClick={() => IntegrationResource.queryIntegration("grading", coercedValue).then(
          (resp) => {
            if (resp.error) {
              alert(resp.error);
            } else {
              updateValue({...coercedValue, grade: resp.result!.numeric, metadata: resp.result!.metadata});
            }
          }, (err) => alert(err)
        )} />
      <Button
        icon="cross"
        disabled={value === undefined}
        onClick={() => updateValue(undefined)}
        title="Clear grade" />
    </ControlGroup>;
  }
}
