import "./ErrorBar.scss";
import { Icon } from "@blueprintjs/core";
import { getErrors, removeError, clearErrors } from "../../store";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { AppError } from "../../models";
import ButtonLink from "./ButtonLink";

function ErrorBanner(props: { e: AppError }) {
  const dispatch = useAppDispatch();

  function close() {
    dispatch(removeError(props.e));
  }

  function linearize(data: Array<Record<string, any>> | Record<string, any>, prefix: string = ""): Array<[string, string]> {
    let result = [] as Array<[string, any]>;
    if (typeof data === "string") {
      result.push([prefix, data]);
    } else if (Array.isArray(data)) {
      (data as Array<Record<string, any>>).forEach(d => {
        result.push(...linearize(d, prefix));
      });
    } else {
      Object.entries(data).forEach(([k, v]) => {
        if (typeof v === "string") {
          // this somehow happened?
          result.push([prefix + "." + k, v]);
        } else {
          const newPrefix = prefix.length > 0 ? `${prefix}.${k}` : k;
          result.push(...linearize(v, newPrefix));
        }
      });
    }
    return result;
  }

  return (
    <div className="ErrorBanner">
      <div className="main-row">
        <Icon icon="warning-sign" />
        <span className="message">{props.e.message}</span>
        <ButtonLink onClick={close}>
          <Icon icon="cross" />
        </ButtonLink>
      </div>
      {props.e.data && (
        <div className="second-row"><ul>{linearize(props.e.data).map((message, index) =>
          <li key={index}><b>{message[0]}</b>: {message[1]}</li>)
        }</ul></div>
      )}
    </div>
  );
}

export default function ErrorBar() {
  const errors = useAppSelector(getErrors);
  const dispatch = useAppDispatch();

  function closeAll() {
    dispatch(clearErrors());
  }

  return (
    <div className="ErrorBar">
      {errors.map((e) => (
        <ErrorBanner key={e._id} e={e} />
      ))}
      {errors.length > 1 && (
        <div className="close-all">
          <ButtonLink className="close-all-button" onClick={closeAll}>
            Dismiss All
          </ButtonLink>
        </div>
      )}
    </div>
  );
}
