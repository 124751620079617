import "./App.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import { CurrentUserProvider } from "./context";
import Header from "./components/shared/Header";
import Footer from "./components/shared/Footer";
import ErrorBar from "./components/shared/ErrorBar";
import BreadcrumbBar from "./components/shared/BreadcrumbBar";
import Home from "./components/pages/Home";
import OAuthCallback from "./components/pages/OAuthCallback";
import UserProfile from "./components/pages/UserProfile";
import Collection from "./components/pages/Collection";
import ItemDetail from "./components/pages/ItemDetail";
import CollectionCreate from "./components/pages/CollectionCreate";
import CollectionUpdate from "./components/pages/CollectionUpdate";
import CollectionImport from "./components/pages/CollectionImport";
import CollectionList from "./components/pages/CollectionList";
import CollectionLists from "./components/pages/CollectionLists";
import Settings from "./components/pages/Settings";
import CollectionListCreate from "./components/pages/CollectionListCreate";
import CollectionListDetail from "./components/pages/CollectionListDetail";
import CollectionListUpdate from "./components/pages/CollectionListUpdate";
import CollectionListItemsEdit from "./components/pages/CollectionListItemsEdit";

function App() {

  return (
    <div className="App">
      <Router>
        <Header />
        <ErrorBar />
        <BreadcrumbBar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/oauth/callback" exact component={OAuthCallback} />
          <Route path="/users/:id" exact component={UserProfile} />
          <Route path="/settings" exact component={Settings} />
          <Route path="/collections" exact component={CollectionList} />
          <Route path="/collections/new" exact component={CollectionCreate} />
          <Route path="/collections/:collectionID" exact component={Collection} />
          <Route path="/collections/:collectionID/edit" exact component={CollectionUpdate} />
          <Route path="/collections/:collectionID/items/:itemID" exact component={ItemDetail} />
          <Route path="/collections/:collectionID/import" exact component={CollectionImport} />
          <Route path="/collections/:collectionID/lists" exact component={CollectionLists} />
          <Route path="/collections/:collectionID/lists/new" exact component={CollectionListCreate} />
          <Route path="/collections/:collectionID/lists/:listID" exact component={CollectionListDetail} />
          <Route path="/collections/:collectionID/lists/:listID/edit" exact component={CollectionListUpdate} />
          <Route path="/collections/:collectionID/lists/:listID/edit-items" exact component={CollectionListItemsEdit} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
