import "./CollectionListForm.scss";
import {
  Button,
  FormGroup,
  HTMLSelect,
  InputGroup,
  Switch,
  TextArea,
} from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../hooks";
import {
  CollectionList,
  CollectionListMutableCreate,
  CollectionListMutableUpdate,
  CollectionWithSuper,
} from "../../models";
import { ListResource } from "../../resources";
import { addError } from "../../store";

interface CollectionListFormProps {
  list?: CollectionList;
  collection: CollectionWithSuper;
  disabled: boolean;
  onCreate?: (list: CollectionListMutableCreate) => void;
  onUpdate?: (list: CollectionListMutableUpdate) => void;
  onDelete?: () => void;
  onCancel: () => void;
}

export default function CollectionListForm(props: CollectionListFormProps) {
  const dispatch = useAppDispatch();
  const [templates, setTemplates] = useState([] as Array<CollectionList>);
  const [formState, setFormState] = useState(
    (props.list ? { ...props.list, template: undefined } : {
      name: "",
      description: "",
      template: undefined,
      is_template: false,
    }) as CollectionListMutableCreate | CollectionListMutableUpdate
  );

  useEffect(() => {
    if (props.collection.super_collection) {
      ListResource.listLists(props.collection.super_collection.id, true).then(
        ({ lists }) => {
          setTemplates(lists);
        },
        (err) => dispatch(addError(err))
      );
    }
  }, [dispatch, props.collection]);

  function setFromTemplate(templateID?: string): void {
    if (!templateID || templateID === "(none)") {
      setFormState({ ...formState, template: undefined });
    } else {
      const template = templates.find((t) => t.id === templateID);
      setFormState({
        ...formState,
        name: template?.name || "",
        description: template?.description || "",
        template: templateID,
        is_template: false,
      });
    }
  }

  return (
    <div className="CollectionListForm">
      {!props.list && (
        <FormGroup
          className="form-row"
          inline={true}
          label="From Template"
          label-for="from-template"
          helperText="If set, copy initial items from the parent set."
        >
          <HTMLSelect
            id="from-template"
            disabled={props.disabled}
            value={(formState as any).template}
            onChange={(e) => setFromTemplate(e.currentTarget.value)}
          >
            <option value={undefined}>(none)</option>
            {templates.map((t) => (
              <option key={t.id} value={t.id}>
                {t.name} ({t.item_count} items)
              </option>
            ))}
          </HTMLSelect>
        </FormGroup>
      )}
      <FormGroup
        className="form-row"
        inline={true}
        label="Name"
        label-for="name"
      >
        <InputGroup
          id="name"
          disabled={props.disabled}
          value={formState.name}
          onChange={(e) => setFormState({ ...formState, name: e.target.value })}
        />
      </FormGroup>
      <FormGroup
        className="form-row"
        inline={true}
        label="Description"
        label-for="description"
      >
        <TextArea
          id="description"
          disabled={props.disabled}
          value={formState.description}
          placeholder="A short description of this list. Can be left blank."
          fill={true}
          onChange={(e) => setFormState({ ...formState, description: e.target.value })}
        />
      </FormGroup>
      <FormGroup
        className="form-row"
        inline={true}
        label="Is Template"
        label-for="is-template"
        helperText="If enabled, this list serves as a set of items rather than a completable checklist. Child collections can then copy this list as a checklist.">
          <Switch id="is-template" disabled={props.disabled || (formState as any).template} checked={formState.is_template} onChange={() => setFormState({...formState, is_template: !formState.is_template})} />
      </FormGroup>
      <div className="button-row">
        {!props.list && (
          <Button
            intent="success"
            disabled={props.disabled || !formState.name}
            onClick={(e) => {
              props.onCreate && props.onCreate(formState);
            }}
            icon="plus"
          >
            Create List
          </Button>
        )}
        {props.list && (<>
          <Button
            intent="success"
            disabled={props.disabled || !formState.name}
            onClick={(e) => {
              props.onUpdate &&
                props.onUpdate(formState as CollectionListMutableUpdate);
            }}
            icon="floppy-disk"
          >
            Save Changes
          </Button>
          <Button
            intent="danger"
            disabled={props.disabled}
            onClick={() => props.onDelete && props.onDelete()}
            icon="trash"
          >
            Delete List
          </Button>
        </>)}
        <Button
          disabled={props.disabled}
          onClick={(e) => {
            props.onCancel();
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}
