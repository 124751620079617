import "./ButtonLink.scss";
import { ReactNode } from "react";

export default function ButtonLink(props: {
  onClick: () => void;
  children: ReactNode;
  title?: string;
  className?: string;
}) {
  return (
    <button
      className={`ButtonLink ${props.className || ""}`}
      onClick={props.onClick}
      title={props.title}
    >
      {props.children}
    </button>
  );
}
