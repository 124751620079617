import "./ItemAdd.scss";
import {
  useMemo,
  useCallback,
  useState,
  FormEvent,
  ReactNode,
  Fragment,
} from "react";
import { Link } from "react-router-dom";
import {
  Card,
  FormGroup,
  InputGroup,
  NumericInput,
  Button,
  ButtonGroup,
  useHotkeys,
  Checkbox,
  HTMLSelect,
  MenuItem,
  Icon,
  Classes,
} from "@blueprintjs/core";
import { MultiSelect } from "@blueprintjs/select";
import { addError } from "../../store";
import { useAppDispatch } from "../../hooks";
import {
  CollectionWithSuper,
  CollectionSchemaField,
  Item,
  ItemWithSuper,
  ImageEntry,
} from "../../models";
import * as CollectionResource from "../../resources/collections";
import SuperItemSuggest from "./SuperItemSuggest";
import ItemImageField from "./ItemImageField";
import * as ImageHelper from "../../helpers/images";
import { IntegrationFormField } from "./IntegrationFormField";
import { FieldValue } from "../../models/item";
import { without } from "../../helpers/util";

interface ItemAddProps {
  collection: CollectionWithSuper;
  onItemAdd: (item: ItemWithSuper) => void;
}

export default function ItemAdd(props: ItemAddProps) {
  const dispatch = useAppDispatch();
  const defaultFormState = useMemo(() => {
    return {
      loading: false,
      superItem: undefined as Item | undefined,
      title: "",
      quantity: 1,
      fields: (Object.fromEntries(
        props.collection.schema.fields
          .filter((f: CollectionSchemaField) => f.type === "choice")
          .map((f: CollectionSchemaField) => [f.name, f.values![0]])
      ) || {}) as Record<string, FieldValue>,
      modifiers: {} as Record<string, boolean>,
      images: [] as Array<ImageEntry>,
    };
  }, [props.collection.schema.fields]);
  const [formOpen, setFormOpen] = useState(false);
  const [formState, setFormState] = useState(defaultFormState);

  function reset() {
    setFormState(defaultFormState);
    setFormOpen(false);
  }

  const resetCallback = useCallback(reset, [defaultFormState]);

  const hotkeys = useMemo(
    () => [
      {
        combo: "A",
        global: true,
        label: "Open item addition form",
        onKeyDown: () => setFormOpen(true),
      },
      {
        combo: "Esc",
        global: true,
        label: "Close item form",
        onKeyDown: resetCallback,
      },
    ],
    [resetCallback]
  );

  const { handleKeyDown } = useHotkeys(hotkeys);

  function handleSubmit(e: FormEvent) {
    e.preventDefault();
    submitForm();
  }

  function updateFormValue(update: Record<string, any>) {
    setFormState({
      ...formState,
      ...update,
    });
  }

  function updateFieldValue(fieldName: string, value: any) {
    let update = {} as Record<string, any>;
    update[fieldName] = value;
    setFormState({
      ...formState,
      fields: {
        ...formState.fields,
        ...update,
      },
    });
  }

  function fields(): Array<CollectionSchemaField> {
    if (props.collection.super_collection) {
      return [
        ...props.collection.schema.fields,
        ...props.collection.super_collection.schema.fields,
      ];
    }

    return props.collection.schema.fields;
  }

  function formField(field: CollectionSchemaField): ReactNode {
    const fieldID = `field-${field.name}`;
    return (
      <FormGroup
        inline={true}
        label={field.display}
        labelFor={fieldID}
        helperText={
          formState.superItem?.fields[field.name] &&
          `Parent Value: ${formState.superItem?.fields[field.name]}`
        }
      >
        {field.type === "number" && (
          <NumericInput
            disabled={formState.loading}
            fill={true}
            id={fieldID}
            value={formState.fields[field.name] as number | undefined}
            onValueChange={(v) => updateFieldValue(field.name, v)}
          />
        )}
        {field.type === "string" && (
          <InputGroup
            disabled={formState.loading}
            fill={true}
            id={fieldID}
            value={formState.fields[field.name] as string | undefined}
            onChange={(e) => updateFieldValue(field.name, e.target.value)}
          />
        )}
        {field.type === "boolean" && (
          <Checkbox
            id={fieldID}
            indeterminate={formState.fields[field.name] as boolean | undefined}
            disabled={formState.loading}
            onChange={(e) =>
              updateFieldValue(field.name, !formState.fields[field.name])
            }
          />
        )}
        {field.type === "choice" && (
          <HTMLSelect
            disabled={formState.loading}
            fill={true}
            id={fieldID}
            value={formState.fields[field.name] as any}
            onChange={(e) => updateFieldValue(field.name, e.target.value)}
          >
            <option value={undefined}></option>
            {field.values &&
              field.values.map((v) => (
                <option key={v} value={v}>
                  {v}
                </option>
              ))}
          </HTMLSelect>
        )}
        {field.type === "integration" && (
          <IntegrationFormField
            item={formState}
            field={field}
            value={formState.fields[field.name] as string | Record<string, any> | undefined}
            editing={true}
            updateValue={(value: any) => updateFieldValue(field.name, value)} />
        )}
      </FormGroup>
    );
  }

  function setSuperItem(item: Item | undefined) {
    setFormState({
      ...formState,
      superItem: item,
      title: item?.title || formState.title,
    });
  }

  function modifiers(): Array<string> {
    if (props.collection.super_collection) {
      return [
        ...props.collection.super_collection!.schema.modifiers,
        ...props.collection.schema.modifiers,
      ];
    }
    return props.collection.schema.modifiers;
  }

  function toggleModifier(mod: string): void {
    if (formState.modifiers[mod]) {
      removeModifier(mod);
    } else {
      addModifier(mod);
    }
  }

  function addModifier(mod: string): void {
    setFormState({
      ...formState,
      modifiers: {...formState.modifiers, [mod]: true},
    });
  }

  function removeModifier(mod: string): void {
    setFormState({
      ...formState,
      modifiers: without(formState.modifiers, mod),
    });
  }

  function clearModifiers(): void {
    setFormState({
      ...formState,
      modifiers: {},
    });
  }

  function tagsField(): ReactNode {
    return (
      <FormGroup inline={true} label="Modifiers">
        <MultiSelect
          fill={true}
          popoverProps={{ minimal: true }}
          items={modifiers().sort()}
          selectedItems={Object.keys(formState.modifiers)}
          noResults={<i>None available</i>}
          onItemSelect={(i, e) => toggleModifier(i)}
          itemRenderer={(m, { handleClick, modifiers }) =>
            modifiers.matchesPredicate && !formState.modifiers[m] ? (
              <MenuItem
                key={m}
                text={m}
                active={modifiers.active}
                onClick={handleClick}
              />
            ) : null
          }
          itemPredicate={(query, item, index, exactMatch) => {
            return item.toLowerCase().indexOf(query.toLowerCase()) >= 0;
          }}
          tagRenderer={(m) => m}
          tagInputProps={{
            disabled: formState.loading,
            onRemove: (e) => removeModifier(e as string),
            rightElement: (
              <Button
                disabled={formState.loading || Object.values(formState.modifiers).length === 0}
                icon="cross"
                minimal={true}
                onClick={clearModifiers}
              />
            ),
          }}
        />
      </FormGroup>
    );
  }

  function imagePicker(): ReactNode {
    return (
      <>
        <FormGroup inline={true} label="Image">
          <ItemImageField
            disabled={formState.loading}
            fill={true}
            onImageAdd={(token) =>
              updateFormValue({
                images: [...formState.images, { token: token, added: "(now)" }],
              })
            }
          />
        </FormGroup>
        {formState.images.map((im) => (
          <div key={im.token} className="image-box">
            <img
              src={ImageHelper.imageURL(im, "small")}
              alt="upload thumbnail"
            />
            <Button
              intent="danger"
              icon="cross"
              minimal={true}
              onClick={() =>
                updateFormValue({
                  images: formState.images.filter((i) => i.token !== im.token),
                })
              }
            />
          </div>
        ))}
      </>
    );
  }

  function formSubmittable(): boolean {
    return formState.title?.length > 0;
  }

  function submitForm(): void {
    if (formSubmittable()) {
      setFormState({
        ...formState,
        loading: true,
      });
      CollectionResource.addItem(props.collection.id, {
        super_item: formState.superItem?.id,
        title: formState.title,
        quantity: formState.quantity,
        fields: Object.fromEntries(
          Object.entries(formState.fields).filter(
            ([k, v]: Array<string | any>) => !!v
          )
        ),
        modifiers: Object.keys(formState.modifiers),
        images: formState.images,
      }).then(
        (newItem) => {
          props.onItemAdd(newItem);
          reset();
        },
        (err) => {
          setFormState({
            ...formState,
            loading: false,
          });
          dispatch(addError(err));
        }
      );
    }
  }

  return (
    <div className="ItemAdd" onKeyDown={handleKeyDown}>
      {formOpen && (
        <Card elevation={2}>
          <div className="header">Add Item</div>
          <form onSubmit={handleSubmit}>
            <div className="form-fields">
              {props.collection.super_collection && (
                <FormGroup inline={true} label="Parent Item">
                  <SuperItemSuggest
                    suggestFrom={props.collection.super_collection}
                    fill={true}
                    disabled={formState.loading}
                    selectedItem={formState.superItem}
                    onSelect={setSuperItem}
                  />
                </FormGroup>
              )}
              <FormGroup inline={true} label="Title" labelFor="title">
                <InputGroup
                  disabled={formState.loading}
                  fill={true}
                  id="title"
                  value={formState.title}
                  onChange={(e) => updateFormValue({ title: e.target.value })}
                />
              </FormGroup>
              <FormGroup inline={true} label="Quantity" labelFor="quantity">
                <NumericInput
                  disabled={formState.loading}
                  fill={true}
                  id="quantity"
                  min={0}
                  value={formState.quantity}
                  onValueChange={(v) =>
                    v >= 0 && updateFormValue({ quantity: v })
                  }
                />
              </FormGroup>
              {fields().map((f) => (
                <Fragment key={`field-${f.name}`}>{formField(f)}</Fragment>
              ))}
              {tagsField()}
              {imagePicker()}
            </div>
            <div className="button-row">
              <Button
                intent="success"
                type="submit"
                disabled={!formSubmittable() || formState.loading}
              >
                Add
              </Button>
              <Button onClick={reset} disabled={formState.loading}>
                Cancel
              </Button>
            </div>
          </form>
        </Card>
      )}
      <ButtonGroup className="owner-controls">
        <Link
          className={Classes.BUTTON}
          to={`/collections/${props.collection.id}/edit`}
          title="Edit Collection"
        >
          <Icon icon="edit" />
        </Link>
        <Link
          className={Classes.BUTTON}
          to={`/collections/${props.collection.id}/import`}
          title="Bulk Import"
        >
          <Icon icon="import" />
        </Link>
        <Button icon="export" title="Bulk Export" disabled={true} />
        {!formOpen && (
          <Button
            icon="plus"
            intent="primary"
            onClick={() => setFormOpen(true)}
          >
            Add Item
          </Button>
        )}
      </ButtonGroup>
    </div>
  );
}
