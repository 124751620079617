import { ReactNode } from "react";
import { Classes } from "@blueprintjs/core";
import { Link } from "react-router-dom";

export default function LinkButton(props: {
  to: string,
  classes?: Array<string>,
  icon?: string,
  children?: ReactNode,
}) {
  let classes: Array<string> = [Classes.BUTTON];
  if (props.classes) {
    classes = [...classes, ...props.classes];
  }
  if (props.icon) {
    classes = [...classes, Classes.iconClass(props.icon)];
  }
  return <Link
    className={classes.join(" ")}
    style={{alignSelf: "center"}}
    to={props.to}>
      {props.children || ""}
    </Link>;
}
