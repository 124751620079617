import "./Pagination.scss";
import { Button, HTMLSelect } from "@blueprintjs/core";

interface PaginationProps {
  page: number;
  perPage: number;
  itemCount: number;
  ellipsize?: number;
  loading?: boolean;
  setPage: (page: number) => void;
  setPerPage: (perPage: number) => void;
}

export default function Pagination(props: PaginationProps) {
  const ellipsePoint = props.ellipsize || 5;
  const pageCount =
    props.itemCount === 0 ? 1 : Math.ceil(props.itemCount / props.perPage);

  function range(start: number, end: number): Array<number> {
    let a = [] as Array<number>;
    for (let i = start; i < end; i += 1) {
      a.push(i);
    }
    return a;
  }

  function setPage(n: number): () => void {
    return () => props.setPage(n);
  }

  return (
    <div className="Pagination">
      <span className="mobile-hidden">
        <Button
          minimal={true}
          title="Previous Page"
          disabled={props.loading || props.page === 0}
          onClick={setPage(0)}
        >
          &laquo;
        </Button>
        <Button
          minimal={true}
          disabled={props.loading || props.page === 0}
          onClick={setPage(0)}
        >
          1
        </Button>
        {props.page - ellipsePoint > 1 && " ... "}
        {range(
          Math.max(props.page - ellipsePoint, 1),
          Math.min(props.page + 1 + ellipsePoint, pageCount - 1)
        ).map((p) => (
          <Button
            key={`page-${p}`}
            minimal={true}
            disabled={props.loading || p === props.page}
            onClick={setPage(p)}
          >
            {p + 1}
          </Button>
        ))}
        {props.page + ellipsePoint < pageCount && " ... "}
        {props.page !== pageCount - 1 && (
          <Button
            minimal={true}
            disabled={props.loading}
            onClick={setPage(pageCount - 1)}
          >
            {pageCount}
          </Button>
        )}
        <Button
          minimal={true}
          title="Next Page"
          disabled={props.loading || props.page === pageCount - 1}
          onClick={setPage(pageCount - 1)}
        >
          &raquo;
        </Button>
      </span>
      <span className="mobile-only">
        <span className="page-label">Page:</span>
        <HTMLSelect
          disabled={props.loading}
          value={props.page + 1}
          options={Array.from(Array(pageCount).keys()).map(k => k+1)}
          onChange={(e) => props.setPage(parseInt(e.currentTarget.value)-1)}
        />
      </span>
      <span className="per-page-label">Per Page:</span>
      <HTMLSelect
        disabled={props.loading}
        value={props.perPage}
        options={[10, 25, 50, 100, 200]}
        onChange={(e) => props.setPerPage(parseInt(e.currentTarget.value))}
      />
    </div>
  );
}
