import { InputGroup } from "@blueprintjs/core";
import { ReactNode } from "react";
import { CollectionSchemaField } from "../../../models";
import { Integration, IntegrationValue, MutableItem } from "./base";


export class TCGPlayerIntegration extends Integration {
  renderValue(
    field: CollectionSchemaField,
    value: IntegrationValue
  ): ReactNode {
    if (!value) {
      return "";
    }
    const url = `https://www.tcgplayer.com/product/${value}/hello/`
    return <a href={url} target="_blank" rel="noreferrer">{value}</a>;
  }

  renderFormField(
    item: MutableItem,
    field: CollectionSchemaField,
    value: IntegrationValue,
    updateValue: (value: IntegrationValue) => void,
  ): ReactNode {
    return <InputGroup
        fill={true}
        value={typeof value === "object" ? "" : value}
        placeholder="TCGPlayer ID"
        onChange={e => updateValue(e.target.value)} />;
  }
}
