import "./CollectionListDetail.scss";
import { ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { setCrumbs, clearCrumbs, addError, getCurrentUser } from "../../store";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Button, ButtonGroup, Card, HTMLTable, Icon } from "@blueprintjs/core";
import {
  CollectionList,
  CollectionListItemWithMatches,
  CollectionWithSuper,
  Item,
  PublicUser,
} from "../../models";
import { CollectionResource, ListResource } from "../../resources";
import Loader from "../shared/Loader";
import CompletionBar from "../lists/CompletionBar";
import ItemListRow from "../collections/ItemListRow";
import ItemThumbnail from "../collections/ItemThumbnail";
import LinkButton from "../shared/LinkButton";

export default function CollectionListDetail() {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(getCurrentUser);
  const params = useParams<{ collectionID: string; listID: string }>();
  const [state, setState] = useState({
    loading: true,
    collection: {} as CollectionWithSuper,
    owner: {} as PublicUser,
    list: {} as CollectionList,
    items: [] as Array<CollectionListItemWithMatches>,
  });
  const [showOwned, setShowOwned] = useState(true);
  const [showImages, setShowImages] = useState(true);
  const [showMatches, setShowMatches] = useState(true);

  useEffect(() => {
    CollectionResource.getCollectionDetail(params.collectionID).then(
      ({ collection, owner }) => {
        ListResource.listItemsWithMatches(
          params.collectionID,
          params.listID
        ).then(
          ({ list, items }) => {
            setState({
              loading: false,
              collection: collection,
              owner: owner,
              list: list,
              items: items,
            });
            dispatch(
              setCrumbs({
                title: `${collection.name} - Lists`,
                items: [
                  { to: `/users/${owner.id}`, text: owner.username },
                  {
                    to: `/collections/${collection.id}`,
                    text: collection.name,
                  },
                  {
                    to: `/collections/${collection.id}/lists`,
                    text: "Lists",
                  },
                  list.name || "",
                ],
              })
            );
          },
          (err) => dispatch(addError(err))
        );
      },
      (err) => dispatch(addError(err))
    );
    return () => {
      dispatch(clearCrumbs());
    };
  }, [dispatch, params.collectionID, params.listID]);

  function thumbnailFor(items: Array<Item>): ReactNode | undefined {
    const itemsWithImages = items.filter(i => i.images.length > 0);
    if (itemsWithImages.length > 0) {
      return <ItemThumbnail item={itemsWithImages[0]} secondary={itemsWithImages.length > 1 ? itemsWithImages[1] : undefined} />;
    }
    return undefined;
  }

  const canEdit = currentUser && (state.collection.owner === currentUser.id || state.collection.curators?.includes(currentUser.id));

  return (
    <Card className="Page CollectionListDetail">
      {state.loading ? (
        <Loader />
      ) : (
        <>
          <div className="completion">
            <div className="header">Completion:</div>
            <CompletionBar
              count={state.list.complete_count}
              total={state.list.item_count}
            />
          </div>
          <ButtonGroup className="toggle-buttons">
            <Button
              icon="tick"
              title="Show owned items"
              disabled={state.list.complete_count >= state.list.item_count}
              intent={showOwned ? "success" : "none"}
              onClick={() => setShowOwned(!showOwned)}
            />
            <Button
              icon="camera"
              title="Show images"
              intent={showImages ? "primary" : "none"}
              onClick={() => setShowImages(!showImages)}
            />
            <Button
              icon="list-detail-view"
              title="Show matching items"
              intent={showMatches ? "primary" : "none"}
              onClick={() => setShowMatches(!showMatches)}
            />
          </ButtonGroup>
          {canEdit && <ButtonGroup className="owner-controls">
            <LinkButton icon="edit" to={`/collections/${state.collection.id}/lists/${state.list.id}/edit`} />
            <LinkButton icon="add-to-artifact" to={`/collections/${state.collection.id}/lists/${state.list.id}/edit-items`} />
          </ButtonGroup>}
          <HTMLTable className="item-table" striped={true} bordered={true}>
            <tbody>
              <tr>
                <th />
                {showImages && <th><Icon icon="camera" /></th>}
                <th>Item ({state.list.item_count})</th>
                <th>Quantity Owned</th>
                {showMatches && <th>Owned Items</th>}
              </tr>
              {state.items.filter(i => showOwned || i.matches.length ===0).map((i) => (
                <tr key={i.id}>
                  <td>
                    {i.matches.length > 0 && (
                      <Icon icon="tick" intent="success" />
                    )}
                  </td>
                  {showImages && <td>{thumbnailFor(i.matching)}</td>}
                  <td>{i.name}</td>
                  <td>{i.matches.reduce((n, i) => i.quantity - 0 + n, 0)}</td>
                  {showMatches && <td>
                    {i.matches.length > 0 && (
                      <HTMLTable
                        className="match-table"
                        bordered={true}
                        condensed={true}
                      >
                        <tbody>
                          {i.matches.map((m) => (
                            <ItemListRow
                              key={m.id}
                              collection={state.collection}
                              childView={false}
                              item={m}
                              minimal={true}
                            />
                          ))}
                        </tbody>
                      </HTMLTable>
                    )}
                  </td>}
                </tr>
              ))}
            </tbody>
          </HTMLTable>
        </>
      )}
    </Card>
  );
}
