import "./FilterField.scss";
import { CollectionWithSuper, CollectionSchemaField } from "../../models";
import { ControlGroup, Text, Tag, Button, Classes } from "@blueprintjs/core";

export default function FilterBox(props: {
  collection: CollectionWithSuper;
  open: boolean;
  setOpen: (open: boolean) => void;
  filters: Record<string, Array<string | number>>;
  removeFilter: (key: string) => void;
  clearFilters: () => void;
  childView: boolean;
}) {
  function fieldName(key: string): string {
    const specialFields = {title: "Title", quantity: "Quantity"} as Record<string, string>;
    if (key in specialFields) {
      return specialFields[key];
    }
    const match = key.match(/^(^super\.field\.|child\.field\.|field\.)?(.*)$/);
    if (!match) {
      console.error("No match?!???!?");
      return key;
    }
    if (props.childView) {
      if (match[1] === "field.") {
        return props.collection.super_collection?.schema.fields.find((f: CollectionSchemaField) => f.name === match[2])?.display || key;
      }
      if (match[1] === "child.field.") {
        return props.collection.schema.fields.find((f: CollectionSchemaField) => f.name === match[2])?.display || key;
      }
    } else {
      if (match[1] === "field.") {
        return props.collection.schema.fields.find((f: CollectionSchemaField) => f.name === match[2])?.display || key;
      }
      if (match[1] === "super.field.") {
        return props.collection.super_collection?.schema.fields.find((f: CollectionSchemaField) => f.name === match[2])?.display || key;
      }
    }
    return key;
  }

  function translateValue(value: Array<string | number>): string {
    const ops = {
      "eq": "=",
      "ne": "!=",
      "lt": "<",
      "gt": ">",
    } as Record<string, string>;

    return `${ops[value[0]] || value[0]} ${value.slice(1).join(', ')}`;
  }

  const filterOptions = Object.entries(props.filters).map(([key, value]) => (
    <Tag key={key} className="filter-tag" onRemove={() => props.removeFilter(key)}>
      <Text ellipsize={true}>{fieldName(key)} {translateValue(value)}</Text>
    </Tag>
  ));

  return (
    <ControlGroup className="FilterField">
      <Button
        className="filter-button"
        onClick={() => props.setOpen(!props.open)}
      >
        Filters
        {props.open ? " (hide)" : " (show)"}
      </Button>
      <div className={[Classes.INPUT, Classes.TAG_INPUT].join(" ")}>
        <div className={Classes.TAG_INPUT_VALUES}>
          {filterOptions}
        </div>
        <Button minimal={true} icon="cross" onClick={props.clearFilters} />
      </div>
    </ControlGroup>
  );
}
