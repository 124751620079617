import "./BreadcrumbBar.scss";
import { Breadcrumbs, Card, Icon } from "@blueprintjs/core";
import { Link } from "react-router-dom";
import { getCrumbs } from "../../store";
import { useAppSelector } from "../../hooks";

export default function BreadcrumbBar() {
  const homeLink = (
    <Link to="/">
      <Icon icon="home" />
    </Link>
  );
  const items = useAppSelector(getCrumbs).map(i => typeof i === "string" ? i : <Link to={(i as any).to}>{(i as any).text}</Link>);

  return (
    <Card id="BreadcrumbBar" elevation={2}>
      <Breadcrumbs items={[homeLink, ...items].map((i) => ({ text: i }))} />
    </Card>
  );
}
