import "./CollectionCreate.scss";
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { addError, setCrumbs, clearCrumbs } from "../../store";
import { useAppDispatch } from "../../hooks";
import { Card } from "@blueprintjs/core";
import * as CollectionResource from "../../resources/collections";
import { CollectionWithSuper } from "../../models";
import Loader from "../shared/Loader";
import CollectionForm from "../collections/CollectionForm";

export default function CollectionUpdate() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const params = useParams<{ collectionID: string }>();
  const [state, setState] = useState({
    loading: true,
    collection: {} as CollectionWithSuper,
  });

  useEffect(() => {
    CollectionResource.getCollectionDetail(params.collectionID).then(
      (resp) => {
        setState({
          loading: false,
          collection: resp.collection,
        });
        dispatch(
          setCrumbs({
            title: resp.collection.name,
            items: [
              { to: `/users/${resp.owner.id}`, text: resp.owner.username },
              {
                to: `/collections/${resp.collection.id}`,
                text: resp.collection.name,
              },
              "Edit",
            ],
          })
        );
      },
      (err) => dispatch(addError(err))
    );
    return () => {
      dispatch(clearCrumbs());
    };
  }, [dispatch, params.collectionID]);

  function openCollection(collectionID: string): void {
    history.push(`/collections/${collectionID}`);
  }

  function goHome(): void {
    history.push("/");
  }

  return (
    <Card className="CollectionCreate">
      {state.loading ? (
        <Loader />
      ) : (
        <CollectionForm
          collection={state.collection}
          onSave={(c) => openCollection(c.id)}
          onDelete={() => goHome()}
        />
      )}
    </Card>
  );
}
