import "./CollectionList.scss";
import { Card, HTMLTable } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../hooks";
import { addError, clearCrumbs, setCrumbs } from "../../store";
import * as CollectionResource from "../../resources/collections";
import Loader from "../shared/Loader";
import { Link } from "react-router-dom";
import ISODate from "../shared/ISODate";

export default function CollectionList() {
  const dispatch = useAppDispatch();
  const [state, setState] = useState({
    loading: true,
    collections: [] as Array<CollectionResource.CollectionWithOwner>,
    count: 1,
  });

  useEffect(() => {
    dispatch(
      setCrumbs({
        title: "Collections",
        items: ["Collections"],
      })
    );
    CollectionResource.listCollections({
      page: 0,
      perPage: 0,
      sort: "name",
      sortAsc: true,
    }).then(resp => setState({loading: false, collections: resp.collections, count: resp.count}), err => dispatch(addError(err)));
    return () => {
      dispatch(clearCrumbs());
    };
  }, [dispatch]);

  return <Card className="Page CollectionList">
    <HTMLTable bordered={true} striped={true} condensed={true}>
      <tbody>
        <tr>
          <th>Name</th>
          <th>Owner</th>
          <th># Entries</th>
          <th># Items</th>
          <th>Last Updated</th>
        </tr>
        {state.loading ? <tr><td colSpan={5}><Loader /></td></tr> : state.collections.map(c => <tr key={c.id}>
          <td><Link to={`/collections/${c.id}`}>{c.name}</Link></td>
          <td><Link to={`/users/${c.owner_obj.id}`}>{c.owner_obj.username}</Link></td>
          <td>{c.item_count}</td>
          <td>{c.item_quantity}</td>
          <td><ISODate timestamp={c.updated} /></td>
        </tr>)}
      </tbody>
    </HTMLTable>
  </Card>;
}
