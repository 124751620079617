import { useEffect } from "react";
import * as AuthHelper from "../../helpers/auth";
import { addError, setCurrentUser } from "../../store";
import { useAppDispatch } from "../../hooks";
import * as AuthResource from "../../resources/auth";
import { Card } from "@blueprintjs/core";

export default function OAuthCallback() {
  const dispatch = useAppDispatch();
  const code = new URLSearchParams(document.location.search).get("code") || "";

  useEffect(() => {
    AuthResource.getOAuthToken(code).then(
      resp => {
        window.localStorage.setItem("authToken", resp.token);
        dispatch(setCurrentUser(AuthHelper.userFromJWT()));
        const returnTo = window.localStorage.getItem("return-to");
        if (!!returnTo) {
          window.localStorage.removeItem("return-to");
          window.location.assign(returnTo);
        } else {
          window.location.assign("/");
        }
      },
      err => dispatch(addError(err))
    );
  }, [dispatch, code]);

  return <Card>Signing in...</Card>;
}
