import { decode, JwtPayload } from "jsonwebtoken";
import { User } from "../models";
import * as AuthResource from "../resources/auth";

interface AssumedUser extends User {
  assumed?: User
}

export function userFromJWT(): AssumedUser | null {
  const token = window.localStorage.getItem("authToken");
  if (token) {
    const decoded = decode(token);
    if (decoded && !(decoded instanceof String)) {
      const j = decoded as JwtPayload;
      console.debug("Parsed JWT", j);
      return {...j.user, "assumed": j.assumed};
    }
    console.debug("Decode error", decoded);
  }
  clearToken();
  return null;
}

export function refreshToken(): Promise<AssumedUser | null> {
  return AuthResource.refreshToken().then(
    resp => {
      if (resp.token) {
        window.localStorage.setItem("authToken", resp.token);
        return userFromJWT();
      }
      return null;
    }
  );
}

export function clearToken(): void {
  window.localStorage.removeItem("authToken");
}
