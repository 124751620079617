export default function ISODate(props: { timestamp: string }) {
  const date = new Date(props.timestamp);
  function zeroPad(n: number): string {
    if (n < 10) {
      return `0${n}`;
    }
    return `${n}`;
  }

  return (
    <span title={date.toDateString()}>
      {date.toString() === "Invalid Date"
        ? props.timestamp
        : `${date.getFullYear()}/${zeroPad(date.getMonth())}/${zeroPad(date.getDate())}`}
    </span>
  );
}
