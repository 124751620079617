import {
  CollectionList,
  CollectionListGroup,
  CollectionListItem,
  CollectionListItemMutable,
  CollectionListItemWithMatches,
  CollectionListMutableCreate,
  CollectionListMutableUpdate,
} from "../models";
import { authFetch } from "./util";

export async function listLists(
  collectionID: string,
  templatesOnly: boolean = false
): Promise<{ lists: Array<CollectionList> }> {
  return authFetch("GET", `/api/collections/${collectionID}/lists`, null, {
    params: { templates: templatesOnly },
  });
}

export async function listListsWithGroups(collectionID: string): Promise<{
  lists: Array<CollectionList>;
  groups: Array<CollectionListGroup>;
}> {
  return authFetch("GET", `/api/collections/${collectionID}/lists`, null, {
    params: { groups: true },
  });
}

export async function createList(
  collectionID: string,
  list: CollectionListMutableCreate
): Promise<CollectionList> {
  return authFetch("POST", `/api/collections/${collectionID}/lists`, list);
}

export async function getList(
  collectionID: string,
  listID: string
): Promise<CollectionList> {
  return authFetch(
    "GET",
    `/api/collections/${collectionID}/lists/${listID}`,
    null,
    { params: { items: false } }
  );
}

export async function updateList(
  collectionID: string,
  listID: string,
  list: CollectionListMutableUpdate
): Promise<CollectionList> {
  return authFetch(
    "PUT",
    `/api/collections/${collectionID}/lists/${listID}`,
    list
  );
}

export async function deleteList(
  collectionID: string,
  listID: string
): Promise<void> {
  return authFetch(
    "DELETE",
    `/api/collections/${collectionID}/lists/${listID}`
  );
}

export async function listItemsWithMatches(
  collectionID: string,
  listID: string
): Promise<{
  list: CollectionList;
  items: Array<CollectionListItemWithMatches>;
}> {
  return authFetch(
    "GET",
    `/api/collections/${collectionID}/lists/${listID}`,
    null,
    { params: { matches: true } }
  );
}

export async function listItems(
  collectionID: string,
  listID: string
): Promise<{
  list: CollectionList;
  items: Array<CollectionListItemWithMatches>;
}> {
  return authFetch(
    "GET",
    `/api/collections/${collectionID}/lists/${listID}`,
    null,
    { params: { matches: false } }
  );
}

export async function patchListItems(
  collectionID: string,
  listID: string,
  newItems: Array<CollectionListItemMutable>,
  updatedItems: Array<CollectionListItem>,
  deletedItems: Array<string>
): Promise<{
  new: number;
  updated: number;
  deleted: number;
}> {
  return authFetch(
    "PATCH",
    `/api/collections/${collectionID}/lists/${listID}`,
    {
      new_items: newItems,
      updated_items: updatedItems,
      deleted_items: deletedItems,
    }
  );
}
