import "./CollectionForm.scss";
import { useState, useEffect, ReactNode } from "react";
import {
  CollectionDetail,
  CollectionWithSuper,
  CollectionSchemaField,
} from "../../models";
import { useAppDispatch } from "../../hooks";
import { addError } from "../../store";
import * as CollectionResource from "../../resources/collections";
import {
  HTMLTable,
  ControlGroup,
  InputGroup,
  FormGroup,
  Button,
  ButtonGroup,
  Tag,
  HTMLSelect,
  TextArea,
  Collapse,
} from "@blueprintjs/core";
import { v4 as uuid } from "uuid";
import { without } from "../../helpers/util";

interface CollectionFormProps {
  collection?: CollectionWithSuper;
  onSave: (collection: CollectionDetail) => void;
  onDelete?: () => void;
}

const templateUserID = "templates";

interface extendedField extends CollectionSchemaField {
  id: string;
}

export default function CollectionForm(props: CollectionFormProps) {
  const dispatch = useAppDispatch();
  const [formState, setFormState] = useState({
    name: props.collection?.name || "",
    template: props.collection?.template,
    public: props.collection?.public !== false,
    archived: props.collection?.archived === true,
    fields: props.collection
      ? props.collection.schema.fields.map(
          (f: CollectionSchemaField) =>
            ({
              ...f,
              id: uuid(),
              values: f.values ? [...f.values] : ([] as Array<string>),
            } as extendedField)
        )
      : ([] as Array<extendedField>),
    modifiers: props.collection
      ? [...props.collection.schema.modifiers]
      : ([] as Array<string>),
  });
  const [templateOptions, setTemplateOptions] = useState({
    loading: true,
    templates: [] as Array<CollectionDetail>,
  });
  const [saving, setSaving] = useState(false);
  const templateCollection = templateOptions.templates.find(
    (t) => t.id === formState.template
  );
  const [newModifier, setNewModifier] = useState("");
  const [deleteShowing, setDeleteShowing] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState("");

  useEffect(() => {
    CollectionResource.listCollectionsForUser(templateUserID, true).then(
      (c) => {
        setTemplateOptions({
          loading: false,
          templates: c,
        });
      },
      (err) => dispatch(addError(err))
    );
  }, [dispatch]);

  function fieldInput(
    field: CollectionSchemaField,
    key: string,
    readOnly: boolean = false
  ): ReactNode {
    return (
      <HTMLTable
        className="field-input"
        bordered={true}
        striped={true}
        condensed={true}
        key={key}
      >
        <tbody>
          <tr>
            <th className="field-title">Display Name</th>
            <td>
              <FormGroup
                helperText={
                  !readOnly &&
                  "The display name of this field. This is what will be visible most of the time."
                }
              >
                <InputGroup
                  value={field.display}
                  disabled={readOnly}
                  onChange={(e) =>
                    updateField(field, "display", e.target.value)
                  }
                />
              </FormGroup>
            </td>
          </tr>
          <tr>
            <th className="field-title">Internal Name</th>
            <td>
              <FormGroup
                helperText={
                  !readOnly &&
                  "The internal name of this field. This must be unique and alphanumeric."
                }
              >
                <InputGroup
                  value={field.name}
                  disabled={readOnly}
                  onChange={(e) => updateField(field, "name", e.target.value)}
                />
              </FormGroup>
            </td>
          </tr>
          <tr>
            <th className="field-title">Type</th>
            <td>
              <FormGroup
                helperText={
                  !readOnly && "The data type represented by this field."
                }
              >
                <HTMLSelect
                  value={field.type}
                  disabled={readOnly}
                  onChange={(e) =>
                    updateField(field, "type", e.currentTarget.value)
                  }
                >
                  <option value="string">Text</option>
                  <option value="number">Numeric</option>
                  <option value="boolean">True/False</option>
                  <option value="choice">Choice</option>
                  <option value="integration">Integration</option>
                </HTMLSelect>
              </FormGroup>
            </td>
          </tr>
          {field.type === "choice" && (
            <tr>
              <th className="field-title">Options</th>
              <td>
                <FormGroup
                  helperText={
                    !readOnly && "Options for this choice. One per line."
                  }
                >
                  <TextArea
                    value={(field.values || []).join("\n")}
                    growVertically={true}
                    disabled={readOnly}
                    onChange={(e) =>
                      updateField(field, "values", e.target.value.split("\n"))
                    }
                  />
                </FormGroup>
              </td>
            </tr>
          )}
          {field.type === "integration" && (
            <tr>
              <th className="field-title">Integration Type</th>
              <td>
                <FormGroup>
                  <HTMLSelect
                    value={field.integration}
                    disabled={readOnly}
                    onChange={(e) =>
                      updateField(field, "integration", e.currentTarget.value)
                    }
                  >
                    <option value={undefined}></option>
                    <optgroup label="Media">
                      <option value="googlebooks">Books</option>
                      <option value="igdb">Video Games</option>
                    </optgroup>
                    <optgroup label="Collectibles">
                      <option value="grading">Graded Items (PSA, CGC, BGS)</option>
                      <option value="tcgplayer">TCGPlayer ID</option>
                    </optgroup>
                  </HTMLSelect>
                </FormGroup>
              </td>
            </tr>
          )}
        </tbody>
      </HTMLTable>
    );
  }

  function addField(): void {
    setFormState({
      ...formState,
      fields: [
        ...formState.fields,
        {
          id: uuid(),
          name: "",
          display: "",
          type: "string",
          values: [] as Array<string>,
        },
      ],
    });
  }

  function updateField(
    field: CollectionSchemaField,
    property: string,
    value: any
  ): void {
    setFormState({
      ...formState,
      fields: formState.fields.map((f) =>
        f === field ? { ...f, ...Object.fromEntries([[property, value]]) } : f
      ),
    });
  }

  function addModifier() {
    setFormState({
      ...formState,
      modifiers: [...formState.modifiers, newModifier],
    });
    setNewModifier("");
  }

  function removeModifier(modifier: string) {
    setFormState({
      ...formState,
      modifiers: formState.modifiers.filter((m) => m !== modifier),
    });
  }

  function save(): void {
    setSaving(true);
    const payload = {
      name: formState.name,
      template: formState.template,
      public: formState.public,
      archived: formState.archived,
      schema: {
        modifiers: formState.modifiers,
        fields: formState.fields.map(
          (f) => without(f, "id") as CollectionSchemaField
        ),
      },
    };
    (props.collection
      ? CollectionResource.updateCollection(props.collection.id, payload)
      : CollectionResource.createCollection(payload)
    ).then(
      (c) => props.onSave(c),
      (err) => {
        dispatch(addError(err));
        setSaving(false);
      }
    );
  }

  function deleteCollection(): void {
    setSaving(true);
    CollectionResource.deleteCollection(props.collection!.id).then(
      () => {
        props.onDelete && props.onDelete();
      },
      (err) => {
        setSaving(false);
        dispatch(addError(err));
      }
    );
  }

  return (
    <div className="CollectionForm">
      <HTMLTable bordered={true} striped={true} condensed={true}>
        <tbody>
          <tr>
            <th className="field-title">Name</th>
            <td>
              <FormGroup helperText="This is the name of your collection.">
                <InputGroup
                  disabled={saving}
                  // fill={true}
                  value={formState.name}
                  onChange={(e) =>
                    setFormState({ ...formState, name: e.target.value })
                  }
                />
              </FormGroup>
            </td>
          </tr>
          <tr>
            <th className="field-title">Parent Collection</th>
            <td>
              <FormGroup helperText='If set, this collection will use the parent collection as a "Master List" - inheriting fields and modifiers, and allowing items from this collection to map to items in the parent. Warning: once set for a collection, this cannot be undone!'>
                <HTMLSelect
                  disabled={saving || !!props.collection?.template}
                  fill={true}
                  value={formState.template || undefined}
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      template: e.currentTarget.value,
                    })
                  }
                >
                  <option value={undefined}>(none)</option>
                  {templateOptions.templates.map((t) => (
                    <option key={t.id} value={t.id}>
                      {t.name}
                    </option>
                  ))}
                </HTMLSelect>
              </FormGroup>
            </td>
          </tr>
          <tr>
            <th className="field-title">Fields</th>
            <td>
              {templateCollection &&
                templateCollection.schema.fields.map((f) =>
                  fieldInput(f, f.name, true)
                )}
              {formState.fields.map((f) => fieldInput(f, f.id))}
              <div>
                <Button icon="plus" onClick={() => addField()}>
                  Add Field
                </Button>
              </div>
            </td>
          </tr>
          <tr>
            <th className="field-title">Modifiers</th>
            <td>
              {templateCollection &&
                templateCollection.schema.modifiers.map((m) => (
                  <Tag
                    key={m}
                    className="modifier inherited"
                    minimal={true}
                    large={true}
                    htmlTitle="Inherited from parent"
                  >
                    {m}
                  </Tag>
                ))}
              {formState.modifiers.map((m) => (
                <Tag
                  key={m}
                  className="modifier"
                  large={true}
                  onRemove={() => removeModifier(m)}
                >
                  {m}
                </Tag>
              ))}

              <FormGroup
                helperText="Modifiers are tags that can be applied to items in your collection."
                inline={true}
              >
                <ControlGroup fill={true}>
                  <InputGroup
                    disabled={saving}
                    value={newModifier}
                    onChange={(e) => setNewModifier(e.target.value)}
                    fill={true}
                  />
                  <Button
                    disabled={
                      !newModifier || formState.modifiers.includes(newModifier)
                    }
                    onClick={() => addModifier()}
                  >
                    Add
                  </Button>
                </ControlGroup>
              </FormGroup>
            </td>
          </tr>
          <tr>
            <th className="field-title">Visibility</th>
            <td>
              <FormGroup helperText="If Public, anyone can see this collection and all items in it. If Private, only you can.">
                <HTMLSelect
                  value={formState.public ? "public" : "private"}
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      public: e.currentTarget.value === "public",
                    })
                  }
                >
                  <option value="public">Public</option>
                  <option value="private">Private</option>
                </HTMLSelect>
              </FormGroup>
            </td>
          </tr>
          {/* <tr>
            <th className="field-title">Status</th>
            <td>
              <FormGroup helperText="If Archived, items cannot be added, edited, or removed.">
                <HTMLSelect
                  value={formState.archived ? "archived" : "open"}
                  onChange={(e) =>
                    setFormState({...formState, archived: e.currentTarget.value === "archived"})
                  }
                >
                  <option value="open">Open</option>
                  <option value="archived">Archived</option>
                </HTMLSelect>
              </FormGroup>
            </td>
          </tr> */}
          <tr>
            <th />
            <td>
              <ButtonGroup fill={true}>
                {props.collection && (
                  <Button
                    intent={deleteShowing ? "warning" : "danger"}
                    icon="trash"
                    disabled={saving}
                    onClick={() => setDeleteShowing(!deleteShowing)}
                  >
                    {deleteShowing ? "Nevermind" : "Delete Collection"}
                  </Button>
                )}
                <Button
                  intent="success"
                  icon="saved"
                  disabled={saving || !formState.name}
                  onClick={() => save()}
                >
                  {props.collection ? "Update Collection" : "Create Collection"}
                </Button>
              </ButtonGroup>
              <Collapse isOpen={deleteShowing}>
                <p>
                  Are you absolutely, 128% certain you want to delete this
                  collection? Once deleted, it can't be restored.
                </p>
                <FormGroup label="Type this collection's name to confirm">
                  <InputGroup
                    value={deleteConfirmation}
                    onChange={(e) => setDeleteConfirmation(e.target.value)}
                    placeholder={props.collection?.name}
                  />
                </FormGroup>
                <Button
                  fill={true}
                  disabled={deleteConfirmation !== props.collection?.name}
                  onClick={() => deleteCollection()}
                  icon="trash"
                  intent="danger"
                >
                  Delete Forever
                </Button>
              </Collapse>
            </td>
          </tr>
        </tbody>
      </HTMLTable>
    </div>
  );
}
