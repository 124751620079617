import "./ItemList.scss";
import { useState, useEffect, ReactNode } from "react";
import { useHistory } from "react-router-dom";
import { HTMLTable, Icon, Card, Switch } from "@blueprintjs/core";
import Loader from "../shared/Loader";
import * as CollectionResource from "../../resources/collections";
import { QueryParams } from "../../helpers/url";
import {
  CollectionWithSuper,
  ItemWithSuper,
  CollectionSchemaField,
  ItemWithChildren,
} from "../../models";
import ItemListRow from "./ItemListRow";
import ItemAdd from "./ItemAdd";
import Pagination from "./Pagination";
import FilterBuilder from "./FilterBuilder";
import FilterField from "./FilterField";
import ButtonLink from "../shared/ButtonLink";
import { addError } from "../../store";
import { useAppDispatch } from "../../hooks";
import LinkButton from "../shared/LinkButton";

interface ItemListProps {
  collection: CollectionWithSuper;
  showControls: boolean;
}

type SortDir = "asc" | "desc";

interface ListSettings {
  page: number;
  perPage: number;
  sortBy: string;
  sortDir: SortDir;
  filters: Record<string, Array<string | number>>;
  childView: boolean;
}

export default function ItemList(props: ItemListProps) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [state, setState] = useState({
    loading: true,
    settings: {
      page: 0,
      perPage: 25,
      sortBy: "added",
      sortDir: "desc" as SortDir,
      filters: {},
      childView: false,
    } as ListSettings,
    items: [] as Array<ItemWithSuper | ItemWithChildren>,
    itemCount: 0,
  });
  const [filtersOpen, setFiltersOpen] = useState(false);
  const qp = new QueryParams(history.location.search);
  const [viewSettings, setViewSettings] = useState({
    page: qp.getInt("page"),
    perPage: qp.getInt("perPage"),
    sortBy: qp.get("sortBy", state.settings.sortBy)!,
    sortDir: qp.get("sortDir", state.settings.sortDir)!,
    filters: qp.getJSON(
      "filters",
      {} as Record<string, Array<string | number>>
    ),
    childView: qp.getBool("childView", state.settings.childView),
  } as Record<string, any>);

  useEffect(() => {
    const qp = new QueryParams(history.location.search);
    const settings = {
      page: qp.getInt("page", state.settings.page)!,
      perPage: qp.getInt("perPage", state.settings.perPage)!,
      sortBy: qp.get("sortBy", state.settings.sortBy)!,
      sortDir: qp.get("sortDir", state.settings.sortDir)!,
      filters: qp.getJSON(
        "filters",
        {} as Record<string, Array<string | number>>
      ),
      childView: qp.getBool("childView", state.settings.childView),
    };
    loadItems(settings); // causes the need for the next line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.collection.id,
    props,
    dispatch,
    history.location.search,
    state.settings.page,
    state.settings.perPage,
  ]);

  function loadItems(settings?: Record<string, any>): void {
    const newSettings = {
      ...state.settings,
      ...settings,
    };
    CollectionResource.getItems(props.collection.id, newSettings).then(
      (resp) =>
        setState({
          settings: newSettings,
          loading: false,
          items: resp.items,
          itemCount: resp.count,
        }),
      (err) => dispatch(addError(err))
    );
  }

  const columnCount =
    5 + // title+quantity+modifiers+thumbnail+added
    (props.showControls ? 1 : 0) +
    Object.keys(props.collection.schema.fields).length +
    Object.keys(props.collection.super_collection?.schema.fields || {}).length;

  function columnHeader(
    name: string,
    display: string,
    prefix: string = ""
  ): ReactNode {
    const sortKey = `${prefix}${name}`;
    return (
      <th key={sortKey}>
        <ButtonLink
          className="header-label"
          onClick={() => {
            if (state.settings.sortBy !== sortKey) {
              updateSettings({
                sortBy: sortKey,
                sortDir: "asc",
                page: 0,
              });
            } else {
              updateSettings({
                sortDir: state.settings.sortDir === "asc" ? "desc" : "asc",
                page: 0,
              });
            }
          }}
        >
          {display}
          {state.settings.sortBy === sortKey && (
            <Icon
              icon={
                state.settings.sortDir === "asc" ? "caret-up" : "caret-down"
              }
            />
          )}
        </ButtonLink>
      </th>
    );
  }

  function updateSettings(settings: Record<string, any>) {
    const newViewSettings = {
      ...Object.fromEntries(
        Object.entries({ ...state.settings, ...viewSettings }).filter(
          ([k, v]) => v !== undefined
        )
      ),
      ...settings,
    };
    const stringifiedViewSettings =
      "filters" in newViewSettings
        ? {
            ...newViewSettings,
            filters: JSON.stringify(newViewSettings.filters),
          }
        : newViewSettings;
    history.push("?" + new URLSearchParams(stringifiedViewSettings));
    setViewSettings(newViewSettings);
  }

  function addFilter(
    filterKey: string,
    filterValues: Array<string | number>
  ): void {
    updateSettings({
      filters: {
        ...state.settings.filters,
        ...viewSettings.filters,
        ...Object.fromEntries([[filterKey, filterValues]]),
      },
    });
  }

  function removeFilter(filterKey: string): void {
    updateSettings({
      filters: Object.fromEntries(
        Object.entries({
          ...state.settings.filters,
          ...viewSettings.Filters,
        }).filter(([k, v]) => k !== filterKey)
      ),
    });
  }

  function toggleChildView(): void {
    function transform(s: string): string {
      const match = s.match(/^(super\.|child\.)?(field\.)?(.+)$/);
      if (!match) {
        // I guess?
        return s;
      }
      if (state.settings.childView) {
        // then self -> super, child -> self
        return `${match[1] ? "" : "super."}${match[2] || ""}${match[3]}`;
      } else {
        // then self -> child, super -> self
        return `${match[1] ? "" : "child."}${match[2] || ""}${match[3]}`;
      }
    }
    const filters = Object.fromEntries(
      Object.entries(viewSettings.filters)
        .filter(([k, v]) => k !== "quantity")
        .map(([k, v]) => [transform(k), v])
    );
    const sortBy = transform(viewSettings.sortBy);
    updateSettings({ childView: !state.settings.childView, filters, sortBy });
  }

  function controls(includeFilters: boolean): ReactNode {
    return (
      <div className="controls">
        <Pagination
          loading={state.loading}
          page={state.settings.page}
          perPage={state.settings.perPage}
          itemCount={state.itemCount}
          setPage={(p: number) => updateSettings({ page: p })}
          setPerPage={(n: number) => updateSettings({ page: 0, perPage: n })}
        />
        {includeFilters && <>
          {props.collection.super_collection && (
            <Switch
              checked={state.settings.childView}
              onChange={() => toggleChildView()}
              inline={true}
              title="Child view"
              alignIndicator="right"
              labelElement={<Icon icon="inheritance" />}
            />
          )}
          <FilterField
            collection={props.collection}
            open={filtersOpen}
            setOpen={setFiltersOpen}
            filters={{
              ...state.settings.filters,
              ...(viewSettings.filters || {}),
            }}
            removeFilter={removeFilter}
            clearFilters={() => updateSettings({ filters: {} })}
            childView={!!state.settings.childView}
          />
          <LinkButton to={`/collections/${props.collection.id}/lists`} icon="numbered-list" />
        </>}
      </div>
    );
  }

  function reloadList(): void {
    loadItems();
  }

  return (
    <div className="ItemList">
      {props.showControls && (
        <ItemAdd collection={props.collection} onItemAdd={() => reloadList()} />
      )}
      <FilterBuilder
        collection={props.collection}
        open={filtersOpen}
        addFilter={addFilter}
        childView={state.settings.childView}
      />
      <Card className="item-view">
        {controls(true)}
        <div className="item-table-wrapper">
          <HTMLTable
            className="item-table"
            bordered={true}
            condensed={true}
            interactive={!state.settings.childView}
            striped={true}
          >
            <thead>
              <tr>
                {!state.settings.childView && props.showControls && <th />}
                <th>
                  <Icon icon="camera" />
                </th>
                {columnHeader("quantity", "Qty")}
                {columnHeader("title", "Title")}
                {!state.settings.childView &&
                  props.collection.schema.fields.map(
                    (f: CollectionSchemaField) =>
                      columnHeader(f.name, f.display, "field.")
                  )}
                {props.collection.super_collection &&
                  props.collection.super_collection.schema.fields.map(
                    (f: CollectionSchemaField) =>
                      columnHeader(
                        f.name,
                        f.display,
                        state.settings.childView ? "field." : "super.field."
                      )
                  )}
                {!state.settings.childView && (
                  <>
                    <th>Modifiers</th>
                    {columnHeader("added", "Added")}
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {state.loading ? (
                <tr>
                  <td colSpan={columnCount}>
                    <Loader />
                  </td>
                </tr>
              ) : (
                <>
                  {state.items.length === 0 && (
                    <tr>
                      <td colSpan={columnCount}>This collection is empty. {props.showControls && "Add something!"}</td>
                    </tr>
                  )}
                  {state.items.map((i) => (
                    <ItemListRow
                      key={i.id}
                      childView={state.settings.childView}
                      collection={props.collection}
                      item={i}
                      showControls={props.showControls}
                      onDelete={() => reloadList()}
                    />
                  ))}
                </>
              )}
            </tbody>
          </HTMLTable>
        </div>
        {controls(false)}
      </Card>
    </div>
  );
}
