import "./Collection.scss";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loader from "../shared/Loader";
import ItemList from "../collections/ItemList";
import * as CollectionResource from "../../resources/collections";
import { CollectionWithSuper, PublicUser } from "../../models";
import { addError, getCurrentUser, setCrumbs, clearCrumbs } from "../../store";
import { useAppSelector, useAppDispatch } from "../../hooks";

export default function Collection() {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(getCurrentUser);
  const params = useParams<{ collectionID: string }>();
  const [state, setState] = useState({
    loading: true,
    collection: {} as CollectionWithSuper,
    owner: {} as PublicUser,
  });

  const showControls =
    state.owner.id === currentUser?.id ||
    (currentUser && state.collection.curators?.includes(currentUser.id)) ||
    false;

  useEffect(() => {
    CollectionResource.getCollectionDetail(params.collectionID).then(
      (resp) => {
        dispatch(
          setCrumbs({
            title: resp.collection.name,
            items: [
              { to: `/users/${resp.owner.id}`, text: resp.owner.username },
              {
                to: `/collections/${resp.collection.id}`,
                text: resp.collection.name,
              },
            ],
          })
        );
        setState((s) => {
          return {
            ...s,
            loading: false,
            collection: resp.collection,
            owner: resp.owner,
          };
        });
      },
      (err) => dispatch(addError(err))
    );
    return () => {
      dispatch(clearCrumbs());
    };
  }, [dispatch, params.collectionID, currentUser]);

  return (
    <div className="Page Collection">
      {state.loading ? (
        <Loader />
      ) : (
        <ItemList collection={state.collection} showControls={showControls} />
      )}
    </div>
  );
}
