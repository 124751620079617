import { configureStore, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as AuthHelper from "./helpers/auth";
import { AppError, User, CrumbLink } from "./models";
import { v4 as uuid } from "uuid";

interface UserState {
  user: User | null;
}

const initialUserState: UserState = {
  user: AuthHelper.userFromJWT(),
};

const currentUserSlice = createSlice({
  name: "currentUser",
  initialState: initialUserState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
  },
});

interface ErrorState {
  errors: Array<AppError>;
}

const initialErrorState: ErrorState = {
  errors: [] as Array<AppError>,
};

function coerceIntoError(obj: any): AppError {
  return {
    _id: uuid(),
    code: obj.code || -2,
    message: obj.message || JSON.stringify(obj),
    data: obj.data,
  } as AppError;
}

const errorSlice = createSlice({
  name: "appErrors",
  initialState: initialErrorState,
  reducers: {
    setError: (state, action: PayloadAction<AppError>) => {
      const error = action.payload;
      state.errors.push(error);
      console.error(`Error ${error.code}: ${error.message}`);
    },
    removeError: (state, action: PayloadAction<AppError>) => {
      state.errors.splice(state.errors.indexOf(action.payload));
    },
    clearErrors: (state) => {
      state.errors.splice(0, state.errors.length);
    },
  },
});

export type Crumb = string | CrumbLink;

interface CrumbState {
  crumbs: Array<Crumb>;
}

const initialCrumbState: CrumbState = {
  crumbs: [] as Array<Crumb>,
};

const crumbSlice = createSlice({
  name: "crumbs",
  initialState: initialCrumbState,
  reducers: {
    setCrumbs: (state, action: PayloadAction<{title: string, items: Array<Crumb>}>) => {
      window.document.title = `Stuff Collector - ${action.payload.title}`;
      state.crumbs.push(...action.payload.items);
    },
    clearCrumbs: (state, action: PayloadAction<void>) => {
      window.document.title = "Stuff Collector";
      state.crumbs.splice(0, state.crumbs.length);
    },
  },
});

export const store = configureStore({
  reducer: {
    currentUser: currentUserSlice.reducer,
    errors: errorSlice.reducer,
    crumbs: crumbSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const { setCurrentUser } = currentUserSlice.actions;
export const getCurrentUser = (state: RootState) => state.currentUser.user;
export const { setError, removeError, clearErrors } = errorSlice.actions;
export const getErrors = (state: RootState) => state.errors.errors;
export const { setCrumbs, clearCrumbs } = crumbSlice.actions;
export const getCrumbs = (state: RootState) => state.crumbs.crumbs;

export function addError(e: any): PayloadAction<AppError, string> {
  return setError(coerceIntoError(e));
}
