import "./CollectionLists.scss";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { setCrumbs, clearCrumbs, addError, getCurrentUser } from "../../store";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Card, Icon, Text } from "@blueprintjs/core";
import {
  CollectionList,
  CollectionListGroup,
  CollectionWithSuper,
  PublicUser,
} from "../../models";
import { CollectionResource, ListResource } from "../../resources";
import Loader from "../shared/Loader";
import LinkButton from "../shared/LinkButton";
import CompletionBar from "../lists/CompletionBar";

export default function CollectionLists() {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(getCurrentUser);
  const params = useParams<{ collectionID: string }>();
  const [state, setState] = useState({
    loading: true,
    collection: {} as CollectionWithSuper,
    owner: {} as PublicUser,
    lists: [] as Array<CollectionList>,
    groups: [] as Array<CollectionListGroup>,
  });

  useEffect(() => {
    CollectionResource.getCollectionDetail(params.collectionID).then(
      ({ collection, owner }) => {
        dispatch(
          setCrumbs({
            title: `${collection.name} - Lists`,
            items: [
              { to: `/users/${owner.id}`, text: owner.username },
              {
                to: `/collections/${collection.id}`,
                text: collection.name,
              },
              "Lists",
            ],
          })
        );
        ListResource.listListsWithGroups(params.collectionID).then(
          ({ lists, groups }) => {
            setState({
              loading: false,
              collection: collection,
              owner: owner,
              lists: lists,
              groups: groups,
            });
          },
          (err) => dispatch(addError(err))
        );
      },
      (err) => dispatch(addError(err))
    );
    return () => {
      dispatch(clearCrumbs());
    };
  }, [dispatch, params.collectionID]);

  const lists = state.lists.filter((l) => !l.is_template);
  const templates = state.lists.filter((l) => l.is_template);
  const canEdit =
    currentUser &&
    (state.collection.owner === currentUser.id ||
      state.collection.curators?.includes(currentUser.id));

  return (
    <Card className="Page CollectionLists">
      {state.loading ? (
        <Loader />
      ) : (
        <>
          <header>Lists</header>
          {lists.length > 0 && (
            <table className="list-table">
              <tbody>
                {lists.map((l) => (
                  <tr key={l.id} className="has-completion">
                    {
                      <td>
                        {l.complete_count >= l.item_count && (
                          <Icon icon="star" intent="warning" />
                        )}
                      </td>
                    }
                    <td className="list-title">
                      <Link
                        to={`/collections/${state.collection.id}/lists/${l.id}`}
                      >
                        {l.name}
                      </Link>
                    </td>
                    <td className="list-completion">
                      <CompletionBar
                        count={l.complete_count}
                        total={l.item_count}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {templates.length > 0 && (
            <>
              <header>Templates</header>
              {templates.map((l) => (
                <div key={l.id} className="list-row">
                  <span className="list-title">
                    <Link
                      to={`/collections/${state.collection.id}/lists/${l.id}`}
                    >
                      {l.name}
                    </Link>
                  </span>
                  <span className="list-item-count">
                    ({l.item_count} items)
                  </span>
                  <Text className="list-description" ellipsize={true}>
                    {l.description}
                  </Text>
                </div>
              ))}
            </>
          )}
          {state.lists.length === 0 && <div>This collection has no lists.</div>}
          {canEdit && (
            <LinkButton
              icon="plus"
              to={`/collections/${state.collection.id}/lists/new`}
            >
              Create List
            </LinkButton>
          )}
        </>
      )}
    </Card>
  );
}
