import "./CollectionLists.scss";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { setCrumbs, clearCrumbs, addError } from "../../store";
import { useAppDispatch } from "../../hooks";
import { Card } from "@blueprintjs/core";
import {
  CollectionList,
  CollectionListMutableUpdate,
  CollectionWithSuper,
  PublicUser,
} from "../../models";
import { CollectionResource, ListResource } from "../../resources";
import Loader from "../shared/Loader";
import CollectionListForm from "../lists/CollectionListForm";

export default function CollectionListUpdate() {
  const dispatch = useAppDispatch();
  const params = useParams<{ collectionID: string; listID: string }>();
  const history = useHistory();
  const [state, setState] = useState({
    loading: true,
    collection: {} as CollectionWithSuper,
    owner: {} as PublicUser,
    list: {} as CollectionList,
  });
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    CollectionResource.getCollectionDetail(params.collectionID).then(
      ({ collection, owner }) => {
        ListResource.getList(params.collectionID, params.listID).then(
          (list) => {
            setState({
              loading: false,
              collection: collection,
              owner: owner,
              list: list,
            });
            dispatch(
              setCrumbs({
                title: `${collection.name} - Edit List`,
                items: [
                  { to: `/users/${owner.id}`, text: owner.username },
                  {
                    to: `/collections/${collection.id}`,
                    text: collection.name,
                  },
                  {
                    to: `/collections/${collection.id}/lists/${list.id}`,
                    text: list.name,
                  },
                  "Edit",
                ],
              })
            );
          },
          (err) => dispatch(addError(err))
        );
      },
      (err) => dispatch(addError(err))
    );
    return () => {
      dispatch(clearCrumbs());
    };
  }, [dispatch, params.collectionID, params.listID]);

  function saveList(list: CollectionListMutableUpdate): void {
    setSaving(true);
    ListResource.updateList(state.collection.id, state.list.id, list).then(
      (newList) => {
        history.push(`/collections/${state.collection.id}/lists/${newList.id}`);
      },
      (err) => {
        setSaving(false);
        dispatch(addError(err));
      }
    );
  }

  function deleteList(): void {
    if (
      window.confirm(
        `Do you really want to delete "${state.list.name}"?\n\nThis can't be undone.`
      )
    ) {
      setSaving(true);
      ListResource.deleteList(state.collection.id, state.list.id).then(
        () => history.push(`/collections/${state.collection.id}/lists`),
        (err) => {
          setSaving(false);
          dispatch(addError(err));
        }
      );
    }
  }

  function cancel(): void {
    history.goBack();
  }

  return (
    <Card className="Page CollectionListUpdate">
      {state.loading ? (
        <Loader />
      ) : (
        <>
          <CollectionListForm
            collection={state.collection}
            list={state.list}
            disabled={saving}
            onUpdate={saveList}
            onDelete={deleteList}
            onCancel={cancel}
          />
        </>
      )}
    </Card>
  );
}
