import "./UserCollectionList.scss";
import { Classes, Icon } from "@blueprintjs/core";
import { Link } from "react-router-dom";
import { Collection } from "../../models";
import ISODate from "../shared/ISODate";
import Loader from "../shared/Loader";
import LinkButton from "../shared/LinkButton";

export default function UserCollectionList(props: {
  collections: Array<Collection>;
  loading: boolean;
  self: boolean;
}) {
  return (
    <div className="UserCollectionList">
      {props.loading ? (
        <Loader />
      ) : (
        <>
          {props.collections.length === 0 && "No collections."}
          {props.collections.map((c) => (
            <div key={c.id} className="collection-row">
              <div className="main-row">
                <Link className="collection-name" to={`/collections/${c.id}`}>
                  {c.name}
                </Link>
                {!c.public && <Icon icon="lock" />}
                {c.archived && " (archived)"}
              </div>
              <div className="secondary-row">
                Last updated <ISODate timestamp={c.updated} />
                {" - "}
                {c.item_count} entries, {c.item_quantity} items.
              </div>
            </div>
          ))}
          {props.self && (
            <LinkButton
              to="/collections/new"
              classes={[Classes.INTENT_PRIMARY, "add-button"]}
              icon="plus"
            >
              New Collection
            </LinkButton>
          )}
        </>
      )}
    </div>
  );
}
