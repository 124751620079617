import "./Footer.scss";
import { BUILD_ID, BUILD_HASH } from "../../settings";

export default function Footer() {
  return <div className="Footer">
    &copy; 2021 <a href="https://alexforan.com" target="_blank" rel="noreferrer">Alex Foran</a>
    <span className="build-info">
      (Build: <code>{BUILD_ID}</code> / <code>{BUILD_HASH}</code>)
    </span>
  </div>;
}
